import { createApp } from 'vue';
import App from './App.vue';
import router, { onRouterReady } from './router';

import { Amplify } from 'aws-amplify';
import awsConfig from './config/AwsConfig';

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';
import './theme/globals.scss';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.scss';

import { quillEditor } from 'vue3-quill';
import { i18n } from './config/VueI18n';

// configure amplify for authentication
Amplify.configure(awsConfig);

const app = createApp(App)
  .use(IonicVue, {
    mode: 'md',
  })
  .use(router)
  .use(i18n)
  .use(quillEditor);

// talk functionality
onRouterReady(app);
