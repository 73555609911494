<script setup>
  import { computed, onMounted, ref } from 'vue';

  const container = ref(null);
  const width = ref(0);

  const props = defineProps({
    /**
     * Holds an array of breakpoints in the format of `[{ name: String, width: Number }]`
     */
    breakpoints: {
      type: Array,
      default: () => [
        { name: 'xs', range: { max: 599 } },
        { name: 'sm', range: { min: 600, max: 959 } },
        { name: 'md', range: { min: 960, max: 1279 } },
        { name: 'lg', range: { min: 1280, max: 1919 } },
        { name: 'xl', range: { min: 1920 } },
      ],
    },

    /**
     * Wether to use the container or the window as width reference.
     */
    windowBased: {
      type: Boolean,
      default: false,
    },
  });

  onMounted(() => {
    window.addEventListener('resize', () => {
      if (container.value == null) return;

      if (props.windowBased) {
        width.value = window.innerWidth;
      } else if (container.value?.offsetWidth) {
        width.value = container.value.offsetWidth;
      }
    });

    /**
     * Super skatchy solution wait for the UI to render
     * https://forum.vuejs.org/t/watching-refs-item-for-change-in-clientheight/60039/4?u=kuhltime
     */
    setTimeout(() => {
      if (!container.value == null) return;

      if (props.windowBased) {
        width.value = window.innerWidth;
      } else if (container.value?.offsetWidth) {
        width.value = container.value.offsetWidth;
      }
    }, 0);
  });

  /**
   * Computes breakpoint ranges based on the passed breakpoints.
   */
  const ranges = computed(() => {
    return props.breakpoints.map(bp => {
      // Add missing range values
      return {
        min: bp.range?.min || 0,
        max: bp.range?.max || 99999,
        name: bp.name,
      };
    });
  });
</script>

<template>
  <!-- Debug: {{ width }} -->
  <div ref="container" class="responsive-slot">
    <template v-for="range in ranges" :key="range.name">
      <slot v-if="range.min <= width && width <= range.max" :name="range.name" />
    </template>
  </div>
</template>

<style lang="scss" scoped>
  .responsive-slot {
    width: 100%;
  }
</style>
