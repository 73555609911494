import { ref, Ref } from 'vue';
import { StrictUseAxiosReturn, useAxios } from '@vueuse/integrations/useAxios';
import { Talk } from '@/views/talk/model/Talk.model';
import { api, noSpinnerToken } from '@/config/AxiosConfig';
import { CandidateData } from '@/views/talk/model/CandidateData';
import { ProjectData } from '@/views/talk/model/ProjectData';
import { Job } from '@/model/Job.model';

export enum TalkType {
  recruiter_service = 'recruiter_service',
  standard = 'standard',
}

export interface OpenTalkByReference {
  applicationId?: string;
  talkType?: TalkType;
  title: string;
  projectId?: string;
  emmyProjectId?: string;
  referenceColor: string;
  talkId?: string;
}

export const screenWidth = 400;
export const fullScreenWidth = 800;
const talkComponentsInitialized: Ref<boolean> = ref(false);
const openTalkId: Ref<string | undefined> = ref(undefined);
const openTalkByReference: Ref<OpenTalkByReference | undefined> = ref(undefined);
const talksSearchCriteria: Ref<string | undefined> = ref(undefined);
const talksSegmentSelected: Ref<string> = ref('talk_all');
const talkOverlayOpen: Ref<boolean> = ref(false);
const talkOverlayFullScreen: Ref<boolean> = ref(false);

const isLoading = ref<boolean>(false);
const isLoadingFirst = ref<boolean>(false);

const {
  data: talksData,
  execute: fetchTalks,
}: StrictUseAxiosReturn<Talk[]> & PromiseLike<StrictUseAxiosReturn<Talk[]>> = useAxios<Talk[]>('', {}, api, {
  immediate: false,
});

const {
  data: candidatesData,
  execute: fetchCandidatesData,
}: StrictUseAxiosReturn<CandidateData[]> & PromiseLike<StrictUseAxiosReturn<CandidateData[]>> = useAxios<
  CandidateData[]
>('', api, { immediate: false });
const {
  data: projectsTalksData,
  execute: fetchProjectsTalks,
}: StrictUseAxiosReturn<ProjectData[]> & PromiseLike<StrictUseAxiosReturn<ProjectData[]>> = useAxios<ProjectData[]>(
  '',
  api,
  { immediate: false },
);

const overlayTalks: Ref<OpenTalkByReference[]> = ref<OpenTalkByReference[]>([]);

export function useWebComponentsGlobalState() {
  const setTalkComponentsInitialized = (initialized: boolean) => {
    talkComponentsInitialized.value = initialized;
  };

  const emmyTalkInit = () => {
    /* initialize global talk event listeners */
    document.addEventListener(
      'emmytalk-open',
      function (e: any) {
        const talkOpen = JSON.parse((e as CustomEvent).detail[0]);
        openTalkByReference.value = undefined;
        if (talkOpen.isOverlay && talkOpen.talkId) {
          if (!overlayTalks.value.find(talk => talk.talkId === talkOpen.talkId)) {
            overlayTalks.value.push({
              talkId: talkOpen.talkId,
              title: talkOpen.title,
              talkType: talkOpen.referenceType,
              referenceColor: talkOpen.referenceColor,
            });
          }
        } else {
          openTalkId.value = talkOpen.talkId;
        }
      },
      true,
    );

    talkComponentsInitialized.value = true;
  };

  const talkApiUrl = process.env.VUE_APP_WEB_COMPONENT_TALK_API_URL ?? '';

  const getTalks = async (hasRecruiterServicesPermission: boolean) => {
    await fetchTalks(`${talkApiUrl}/talks/findAll?${noSpinnerToken}`, {
      method: 'POST',
      data: {
        jobIds: [],
        applicationIds: [],
        talkType: resolveUserTalkTypes(hasRecruiterServicesPermission),
      },
    });
  };

  const getCandidatesData = async (paginationIndex: number, findBy?: string) => {
    await fetchCandidatesData(
      `/v1/projects/applications/find?${noSpinnerToken}&paginationUse=true&paginationStartIndex=${paginationIndex}&onlyEssentialData=true&paginationPageSize=20&findBy=${
        findBy ?? ''
      }`,
    );
  };

  const getProjectsTalks = async (paginationIndex: number, findBy?: string) => {
    await fetchProjectsTalks(
      `/v1/projects?${noSpinnerToken}&paginationUse=true&paginationStartIndex=${paginationIndex}&paginationPageSize=20&onlyEssentialData=true&findBy=${
        findBy ?? ''
      }`,
    );
  };

  const closeOverlayTalk = (talkByReference: OpenTalkByReference) => {
    if (talkByReference) {
      overlayTalks.value = overlayTalks.value.filter(talk => {
        const matched = talk.talkId
          ? talk.talkId === talkByReference.talkId
          : talk.applicationId === talkByReference.applicationId &&
            talk.talkType === talkByReference.talkType &&
            talk.title == talkByReference.title &&
            talk.projectId === talkByReference.projectId &&
            talk.emmyProjectId === talkByReference.emmyProjectId;
        return !matched;
      });
    }
  };

  const openProjectDiscussionTalk = (project: Job, projectColor: string) => {
    if (project) {
      const talkReferenceObject = {
        id: '',
        talkType: TalkType.standard,
        title: project.name,
        projectId: project.id,
        emmyProjectId: project.emmyProjectId,
        referenceColor: projectColor,
      };

      openTalkId.value = undefined;

      if (
        !overlayTalks.value.find(
          talk =>
            talk.projectId === talkReferenceObject.projectId &&
            talk.emmyProjectId === talkReferenceObject.emmyProjectId &&
            talk.talkType === talkReferenceObject.talkType,
        )
      ) {
        overlayTalks.value.push(talkReferenceObject);
      }
      talkOverlayOpen.value = true;
    }
  };

  const openProjectRecruitingServicesTalk = (project: Job, projectColor: string) => {
    if (project) {
      const talkReferenceObject = {
        id: '',
        talkType: TalkType.recruiter_service,
        title: project.name,
        projectId: project.id,
        emmyProjectId: project.emmyProjectId,
        referenceColor: projectColor,
      };

      openTalkId.value = undefined;

      if (
        !overlayTalks.value.find(
          talk =>
            talk.projectId === talkReferenceObject.projectId &&
            talk.emmyProjectId === talkReferenceObject.emmyProjectId &&
            talk.talkType === talkReferenceObject.talkType,
        )
      ) {
        overlayTalks.value.push(talkReferenceObject);
      }
      talkOverlayOpen.value = true;
    }
  };

  const resolveUserTalkTypes = (hasRecruiterServicesPermission: boolean): TalkType[] => {
    return hasRecruiterServicesPermission ? [TalkType.recruiter_service, TalkType.standard] : [TalkType.standard];
  };

  return {
    emmyTalkInit,
    setTalkComponentsInitialized,
    talkComponentsInitialized,
    openTalkId,
    openTalkByReference,
    talksSearchCriteria,
    talksSegmentSelected,
    talkOverlayOpen,
    talkOverlayFullScreen,
    talksData,
    candidatesData,
    projectsTalksData,
    overlayTalks,
    getTalks,
    getCandidatesData,
    getProjectsTalks,
    closeOverlayTalk,
    screenWidth,
    fullScreenWidth,
    openProjectDiscussionTalk,
    openProjectRecruitingServicesTalk,
    isLoading,
    isLoadingFirst,
  };
}
