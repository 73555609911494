<template>
  <ion-card
    v-if="openByReference"
    :style="{
      width: componentWidth + 'px',
      borderTopColor: openByReference.referenceColor,
    }"
    class="unsetWidth"
  >
    <emmy-talk
      v-if="accessToken && idToken && currentUser"
      :key="talkKey"
      :locale="locale"
      :access-token="accessToken"
      :id-token="idToken"
      :base-api-url="talkApiUrl"
      :talk-id="talkId"
      :current-user="currentUser"
      :is-mobile="`${isMobile()}`"
      :is-overlay="true"
      :title-color="titleColor"
      :theme="theme"
      :talk-type="openByReference?.talkType"
      :job-ids="useEmmyTalk().getProjectReference(openByReference)"
      :application-ids="useEmmyTalk().getApplicationsReference(openByReference)"
      :tenant-id="tenantId"
      @emmytalk-close="closeMessage"
      @emmytalk-toggle-fullscreen="toggleFullscreen"
    ></emmy-talk>
  </ion-card>
</template>

<script setup lang="ts">
  import { IonCard } from '@ionic/vue';
  import { isPlatform } from '@ionic/vue';
  import {
    OpenTalkByReference,
    screenWidth,
    fullScreenWidth,
    useWebComponentsGlobalState,
  } from '@/composables/useWebComponentsGlobalState';
  import { computed, ref, Ref } from 'vue';
  import { Auth } from 'aws-amplify';
  import { useI18n } from 'vue-i18n';
  import { useEmmyTalk } from '@/views/talk/composables/useEmmyTalk';

  const { locale } = useI18n();
  const props = defineProps<{
    openByReference: OpenTalkByReference;
  }>();

  const { closeOverlayTalk } = useWebComponentsGlobalState();

  const accessToken: Ref<string | undefined> = ref(undefined);
  const idToken: Ref<string | undefined> = ref(undefined);
  const currentUser: Ref<string | undefined> = ref(undefined);
  const isFullscreen: Ref<boolean> = ref(false);

  Auth.currentSession().then(data => {
    accessToken.value = data.getAccessToken().getJwtToken();
    idToken.value = data.getIdToken().getJwtToken();
    currentUser.value = data.getAccessToken().payload.username;
  });
  const talkApiUrl = process.env.VUE_APP_WEB_COMPONENT_TALK_API_URL ?? '';

  const isMobile = () => {
    if (isPlatform('mobile')) {
      return true;
    }

    return false;
  };

  const talkKey = computed(() => {
    return `talk-${useEmmyTalk().getTalkDOMIdentifier(props.openByReference)}`;
  });

  const talkId = computed(() => {
    return props.openByReference.talkId;
  });

  const titleColor = computed(() => {
    return props.openByReference.referenceColor ?? '#02A79F';
  });

  const theme = computed(() => {
    return props.openByReference.title;
  });

  const tenantId = computed(() => {
    return process.env.VUE_APP_TENANT_ID ?? '';
  });

  const componentWidth = computed(() => {
    return isFullscreen.value ? fullScreenWidth : screenWidth;
  });

  const closeMessage = () => {
    closeOverlayTalk(props.openByReference);
  };

  const toggleFullscreen = () => {
    isFullscreen.value = !isFullscreen.value;
  };
</script>

<style scoped lang="scss">
  ion-card {
    border-top-style: solid;
    border-top-width: 14px;
    border-radius: 10px 10px 0 0;
    box-shadow: 0 1px 22px rgb(33 96 147 / 20%);
    margin-top: 0;
    margin-bottom: 0;
    width: 400px;
  }

  @media (max-width: 899px) {
    ion-card {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      margin: 0;
      width: 100%;
    }
  }
</style>
