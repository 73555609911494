import { Auth } from 'aws-amplify';
import { CognitoUser } from '@aws-amplify/auth';
import { Permission, PermissionAction } from '@/model/EmmyScorePermissions.model';
import { api } from '@/config/AxiosConfig';
import { useAxios } from '@vueuse/integrations/useAxios';
import { Method } from 'axios';
import { toastController } from '@ionic/vue';
import { warningOutline } from 'ionicons/icons';
import { ComposerTranslation } from 'vue-i18n';
import { usePushNotifications } from './usePushNotifications';
import { useAuthGlobalState } from '@/composables/useAuthGlobal';
import { UserProfile } from '@/model/user-profile.model';
import { Permissions } from '@/model/permission.model';

export function useAuth() {
  const pushNotifications = usePushNotifications();

  const login = async (username: string, password: string | undefined): Promise<CognitoUser | undefined> => {
    const authResponse = await Auth.signIn(username, password);
    pushNotifications.registerDevice();
    return authResponse;
  };

  const logout = async (): Promise<unknown> => {
    await pushNotifications.unregisterDevice();
    return await Auth.signOut();
  };

  const forgotPassword = async (username: string) => {
    return await Auth.forgotPassword(username);
  };

  const forgotPasswordSubmit = async (username: string, code: string, password: string) => {
    return await Auth.forgotPasswordSubmit(username, code, password);
  };

  const checkUserHavePermission = async (permission: Permission): Promise<boolean> => {
    if (process.env.NODE_ENV === 'development') {
      return true;
    }
    const { userProfile } = useAuthGlobalState();
    return userProfile.value?.permissions.includes(permission) ?? false;
  };

  const checkUserHasReviewerJobPermission = async (jobId: string | undefined): Promise<boolean> => {
    // TODO fix me
    return false;
  };

  const getUserFullName = async (): Promise<string> => {
    const idTokenPayload = (await Auth.currentSession())?.getIdToken().payload;
    return `${idTokenPayload.given_name} ${idTokenPayload.family_name}`;
  };

  const doUserPermissionAction = async (
    permission: Permission,
    action: PermissionAction,
    t: ComposerTranslation,
  ): Promise<boolean> => {
    if (process.env.NODE_ENV === 'development') {
      console.log(`In development mode, do user permission action: ${action}, for permission: ${permission}`);
      return true;
    }

    const url: string = getUrl(permission, action);
    const method: Method = getPermissionsMethod(action);

    const { error } = await useAxios<void>(url, { method: method }, api);

    if (!error.value) {
      return true;
    }

    await showErrorToast(error.value.response, t, action);
    return false;
  };

  return {
    login,
    logout,
    forgotPassword,
    forgotPasswordSubmit,
    checkUserHavePermission,
    checkUserHasReviewerJobPermission,
    getUserFullName,
    doUserPermissionAction,
  };
}

function getUrl(permission: Permission, permissionAction: PermissionAction): string {
  if (permission === Permission.ProjectCreate) {
    return `/v1/permissions/project/create/${permissionAction}`;
  } else if (permission === Permission.ProjectLongtest) {
    return `/v1/permissions/longTest/create/${permissionAction}`;
  }

  return '';
}

function getPermissionsMethod(permissionAction: PermissionAction): Method {
  if (permissionAction === PermissionAction.Reserve) {
    return 'GET';
  }

  return 'POST';
}

async function showErrorToast(error: any, t: ComposerTranslation, permissionAction: PermissionAction) {
  let message = t('licenses.noFree');
  if (
    error.status !== 417 &&
    error.status !== 403 &&
    (permissionAction !== PermissionAction.Reserve ||
      (permissionAction === PermissionAction.Reserve && error.status !== 401))
  ) {
    message = error.data && error.data.errorDetails ? error.data.errorDetails.error : t('general.generalErrorMsg');
  }

  const toast = await toastController.create({
    message: `${message}`,
    icon: warningOutline,
    position: 'bottom',
    duration: 14000,
    buttons: [
      {
        text: t(`general.dismiss`),
        role: 'cancel',
      },
    ],
    cssClass: 'error-toast',
    color: 'warning',
  });
  await toast.present();
}
