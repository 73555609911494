import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-df77c6fa"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["locale", "access-token", "id-token", "base-api-url", "caller-reference-object", "talk-id", "tenant-id", "talk"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass($setup.isLoadingFirst ? 'blur' : 'noBlur')
    }, [
      ($setup.activeSegment == 'talk_projects' && $props.filteredProjectTalks)
        ? (_openBlock(), _createBlock($setup["IonList"], {
            key: 0,
            ref: "projectElement",
            class: _normalizeClass(["scroll-fix emmytalk-fullscreen", {
        'max-height': !$props.isOverlay && !$setup.isEmmyTalk,
        'max-height-emmyTalk': !$props.isOverlay && $setup.isEmmyTalk,
      }])
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.filteredProjectTalks, (projectData) => {
                return (_openBlock(), _createBlock($setup["IonItem"], {
                  key: `project_message_overview-${projectData.id}`
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["ProjectMessageOverview"], {
                      "project-data": projectData,
                      "is-overlay": $props.isOverlay
                    }, null, 8 /* PROPS */, ["project-data", "is-overlay"])
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1024 /* DYNAMIC_SLOTS */))
              }), 128 /* KEYED_FRAGMENT */))
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["class"]))
        : _createCommentVNode("v-if", true),
      ($setup.activeSegment == 'talk_all' && $props.filteredTalks)
        ? (_openBlock(), _createBlock($setup["IonList"], {
            key: `talk_overview_list-${$props.filteredTalks.length}`,
            class: _normalizeClass(["scroll-fix emmytalk-fullscreen", {
        'max-height': !$props.isOverlay,
      }])
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.filteredTalks, (talk) => {
                return (_openBlock(), _createBlock($setup["IonItem"], {
                  key: `talk_overview_${talk._id}${talk.lastMessage ? talk.lastMessage._id : ''}`
                }, {
                  default: _withCtx(() => [
                    ($setup.accessToken.length > 0 && $setup.idToken.length > 0)
                      ? (_openBlock(), _createElementBlock("emmy-talk-overview-card", {
                          key: `talk_overview_${talk._id}_${new Date().getTime()}`,
                          locale: $setup.locale,
                          "access-token": $setup.accessToken,
                          "id-token": $setup.idToken,
                          "base-api-url": $setup.talkApiUrl,
                          "caller-reference-object": $setup.getTalkReferenceObjectJSON(talk, $props.isOverlay),
                          "talk-id": talk._id,
                          "tenant-id": $setup.tenantId(),
                          talk: talk,
                          "show-inactive": "false",
                          style: {"width":"100%","height":"90%","padding-right":"8px"}
                        }, null, 8 /* PROPS */, _hoisted_1))
                      : _createCommentVNode("v-if", true)
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1024 /* DYNAMIC_SLOTS */))
              }), 128 /* KEYED_FRAGMENT */))
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["class"]))
        : _createCommentVNode("v-if", true),
      ($setup.activeSegment == 'talk_candidates' && $props.filteredCandidateTalks)
        ? (_openBlock(), _createBlock($setup["IonList"], {
            key: 2,
            ref: "candidateElement",
            class: _normalizeClass(["scroll-fix emmytalk-fullscreen", {
        'max-height': !$props.isOverlay,
      }])
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.filteredCandidateTalks, (candidateTalk) => {
                return (_openBlock(), _createBlock($setup["IonItem"], {
                  key: `candidate_overview_${candidateTalk.id}`
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["CandidateMessageOverview"], {
                      "candidate-data": candidateTalk,
                      "is-overlay": $props.isOverlay,
                      "show-left-border": true,
                      "show-project-name": true
                    }, null, 8 /* PROPS */, ["candidate-data", "is-overlay"])
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1024 /* DYNAMIC_SLOTS */))
              }), 128 /* KEYED_FRAGMENT */))
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["class"]))
        : _createCommentVNode("v-if", true),
      ($setup.activeSegment == 'talk_services' && $props.filteredServicesTalks)
        ? (_openBlock(), _createBlock($setup["IonList"], {
            key: 3,
            ref: "projectElement",
            class: _normalizeClass(["scroll-fix emmytalk-fullscreen", {
        'max-height': !$props.isOverlay,
      }])
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.filteredServicesTalks, (servicesTalk) => {
                return (_openBlock(), _createBlock($setup["IonItem"], {
                  key: `services_overview_${servicesTalk.id}`
                }, {
                  default: _withCtx(() => [
                    _createVNode($setup["ProjectMessageOverview"], {
                      "project-data": servicesTalk,
                      "is-overlay": $props.isOverlay,
                      "is-recruitment-services": true
                    }, null, 8 /* PROPS */, ["project-data", "is-overlay"])
                  ]),
                  _: 2 /* DYNAMIC */
                }, 1024 /* DYNAMIC_SLOTS */))
              }), 128 /* KEYED_FRAGMENT */))
            ]),
            _: 1 /* STABLE */
          }, 8 /* PROPS */, ["class"]))
        : _createCommentVNode("v-if", true)
    ], 2 /* CLASS */),
    ($setup.isLoadingFirst)
      ? (_openBlock(), _createBlock($setup["IonSpinner"], {
          key: 0,
          name: "crescent",
          class: "spin-center"
        }))
      : _createCommentVNode("v-if", true),
    ($setup.isLoading)
      ? (_openBlock(), _createBlock($setup["IonSpinner"], {
          key: 1,
          name: "crescent",
          class: "spin"
        }))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}