import { StrictUseAxiosReturn, useAxios } from '@vueuse/integrations/useAxios';
import { api, noSpinnerToken } from '@/config/AxiosConfig';
import { JobCandidate } from '../model/JobCandidate.model';
import { Job } from '@/model/Job.model';

export function useProjectsList() {
  const { data, isLoading, execute }: StrictUseAxiosReturn<Job[]> & PromiseLike<StrictUseAxiosReturn<Job[]>> = useAxios<
    Job[]
  >('', api, { immediate: false });

  const findJobs = (findBy: string, noSpinner: boolean) => {
    execute(`/v1/projects/find?findBy=${findBy}${noSpinner ? '&' + noSpinnerToken : ''}`);
  };

  return {
    data: data,
    isLoading,
    findJobs,
  };
}
