import { createVNode as _createVNode, withModifiers as _withModifiers, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-11eadf19"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "candidate__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock($setup["IonCard"], {
    class: _normalizeClass($props.showLeftBorder ? 'project-color' : ''),
    style: _normalizeStyle($props.showLeftBorder ? { borderLeftColor: $props.candidateData.referenceColor } : ''),
    onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.openCandidate()))
  }, {
    default: _withCtx(() => [
      _createVNode($setup["StatusBar"], {
        class: "candidate-status",
        display: $props.candidateData.flowHistory.byNewestFirst
      }, null, 8 /* PROPS */, ["display"]),
      _createVNode($setup["IonCardHeader"], null, {
        default: _withCtx(() => [
          _createVNode($setup["Avatar"], { size: 64 }),
          _createVNode($setup["StartChatButton"], {
            button: "true",
            class: "chat-button",
            onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => ($setup.openTalk()), ["stop"]))
          }),
          _createElementVNode("div", _hoisted_1, [
            _createVNode($setup["IonCardTitle"], { class: "wrap-text" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString($props.candidateData.contactInfo.fullName), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            }),
            ($props.showProjectName)
              ? (_openBlock(), _createBlock($setup["IonCardSubtitle"], {
                  key: 0,
                  class: "project wrap-text"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('emmyTalk.project')) + ": " + _toDisplayString($props.candidateData.jobOpeningName), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                }))
              : _createCommentVNode("v-if", true)
          ])
        ]),
        _: 1 /* STABLE */
      })
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["class", "style"]))
}