export enum Permissions {
  ViewParticipations,
  ListParticipations,
  ListParticipationJobOpenings, // Allows the user to access the jobopening tiles
  ViewParticipationJobOpening,
  ViewParticipationDetails,
  ViewParticipationCalculationDetails,
  ViewParticipationHistory,
  ViewJobOpeningHistory,
  ViewJobOpeningPublication,
  MoveParticipationToLongList,
  MoveParticipationToShortList,
  RejectParticipation,
  UpdateParticipationState,
  UpdateAdditionalInformationOnParticipation,
  EditAdditionalCvRequirementsStatement,
  UploadAttachments,
  DeleteAttachments,
  DeleteAnyAttachment,
  LabelParticipations,

  UpdateContactInfo,
  DeleteParticipations,
  DeleteParticipationsInAnyStage,
  DiscussParticipation,
  AddNoteToParticipation,
  Rate,
  HireParticipation,
  PrepareParticipations,
  SendDirectMessagesToCandidate,
  FindParticipations,

  ViewParticipationNotifications,
  ViewParticipationDiscussionNotifications,
  ViewJobDiscussionNotifications,
  EditParticipationNotifications,
  EditParticipationDiscussionNotifications,
  EditJobDiscussionNotifications,
  EditUserInterfacePreference,
  ReceiveParticipationNotifications,
  ReceiveParticipationDiscussionNotifications,
  ReceiveJobDiscussionNotifications,

  ViewParticipationDiscussion,

  SeePersonalCandidateDetails,
  SeeRecruiterOnlyLabels,
  SeePersonalityProfile,

  ViewReviewers,
  ViewReviewerAccessLink,
  EditReviewers,
  DeleteReviewers,

  ViewInternalReviewers,
  InviteReviewer,
  DeleteReviewer,

  ReviewParticipations,

  ViewJobs,
  ViewJobPreview,
  CopyJobs,
  EditJobs,
  DeleteDraftOrReleaseCandidateJobs,
  DeleteJobs,
  EditJobSettings,
  EditProfileOfJob,
  UpdateJobScreening,
  UpdateJobAssessing,
  EditJobOrganizations,
  ViewTemplateJobs,
  EditTemplateJobs,
  ViewAppliedTraits,
  FindJobs,
  EditJobLanguages,

  ChangeJobState,
  MarkJobAsReleaseCandidate,
  ChangeJobStateFromReleaseCandidateToDraft,
  PublishJob,
  ChangeJobStateFromPublishedToDraft,
  CloseJob,
  TerminateJob,
  ReopenFromClosedJob,
  ReopenFromTerminatedJob,
  ArchiveJob,

  ViewJobEditorCharacterization,
  ViewJobEditorDescription,
  ViewJobEditorProfile,
  ViewJobEditorCvRequirements,
  ViewJobEditorSettings,
  ViewJobEditorTexts,
  ViewJobEditorPublication,
  ViewJobEditorOrganizations,

  ViewJobAds,
  EditJobAds,

  ViewJobStatistics,
  ViewJobTrackingStatistics,

  ViewJobCollaboration,
  ViewAdditionalInformationOnJob,
  UpdateAdditionalInformationOnJob,
  ViewJobAttachments,
  EditJobAttachments,
  DeleteJobAttachments,
  ViewJobDiscussion,
  DiscussJob,
  ViewCoordinationDiscussion,
  DiscussCoordination,
  ViewJobOpeningInvolvedUsers,

  ViewJobDashboard,
  AccessMarketingModule,

  ViewStandardJobSettingsTemplate,
  ViewJobSettingsTemplates,
  ExtractJobSettingsTemplates,
  AddNewJobSettingsTemplates,
  EditJobSettingsTemplates,
  DeleteJobSettingsTemplates,
  EditJobSettingsTemplateOrganizations,

  ViewJobProfiles,

  ViewLabelsOnJob,
  ViewJobLabels,
  EditJobLabels,
  DeleteJobLabels,
  LabelJobOpening,
  AssignJobLabel,
  UnassignJobLabel,

  ViewTenant,
  EditTenant,
  ViewCandidateExperienceSettings,
  ViewReviewerSettings,
  ViewLabels,
  ViewJobBoardSettings,
  ViewParticipationListConfig,
  ViewReportSettings,
  ViewLanguageSettings,
  EditLanguageSettings,
  EditTenantAuthentication,

  ViewUsers,
  EditUsers,
  DeleteUsers,
  ViewUserProfile,
  EditUserProfile,
  EditUserOrganizations,

  ViewEmailDomainWhitelist,
  EditEmailDomainWhitelist,
  DeleteEmailDomainWhitelist,
  EditReviewerInvitationTemplate,

  ViewSenderEmailDomainWhitelist,
  EditSenderEmailDomainWhitelist,
  DeleteSenderEmailDomainWhitelist,

  ViewDataPrivacy,
  AddDataPrivacyPolicy,
  EditDpoContact,

  ViewOrganizations,
  EditOrganizations,
  DeleteOrganizations,

  ViewReviewerResponsibilities,
  ViewJobResponsibilities,
  AddJobResponsibilities,
  DeleteJobResponsibilities,
  ChangeHiringManager,

  SearchUsers,

  ViewJobsInOrganizations,
  AttachCandidateReportToRejectionNotification,

  ViewUtilities,
  AccessReviewerUtilities,
  AccessCandidateUtilities,

  ViewFullDataCsvReport,

  // Special permissions
  AccessAllParticipations, // Without this, a user must aquire specific access rights to a certain participation (used with Reviewer)

  AssignHiringManagerJobResponsibilityToSelf, // This permission can be used to assign job responsibilities upon job opening creation. See the JobResponsibilityAndOrganizationCalculatorService for further details

  FeaturePreview,

  // For backward compatibility
  emmyscore_viewer,
  adminconsole_access,

  // Temporary Talk permission for Reviewer
  ExternalReviewerRestrictions,
}
