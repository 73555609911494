import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, Teleport as _Teleport, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f88b61ea"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: "sidebarSeparator",
  style: {"padding-right":"65px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (!$setup.isTalkRoute)
    ? (_openBlock(), _createBlock(_Teleport, {
        key: 0,
        to: "body"
      }, [
        _createElementVNode("div", {
          class: _normalizeClass(["overlay mobile", {
        emmyTalkOpen: $setup.talkOverlayOpen,
        emmyTalkFullscreen: $setup.talkOverlayFullScreen,
      }]),
          style: _normalizeStyle({
        'padding-top': $setup.talkOverlayOpen && $setup.isMobile && $setup.talkOverlayFullScreen ? '64px' : '0px',
      })
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.overlayTalks, (overlayTalk) => {
            return (_openBlock(), _createElementBlock("div", {
              key: $setup.useEmmyTalk().getTalkDOMIdentifier(overlayTalk)
            }, [
              _createVNode($setup["TalkMessageOverlay"], { "open-by-reference": overlayTalk }, null, 8 /* PROPS */, ["open-by-reference"])
            ]))
          }), 128 /* KEYED_FRAGMENT */)),
          (!$setup.isSidebarOpen)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1))
            : _createCommentVNode("v-if", true),
          _createVNode($setup["TalkSidebar"], {
            "filtered-talks": $setup.filteredTalks,
            "filtered-candidate-talks": $setup.filteredCandidateTalks,
            "filtered-project-talks": $setup.filteredProjectTalks,
            "filtered-services-talks": $setup.filteredServicesTalks,
            "is-overlay": true,
            "is-reached-candidate-end": $setup.isReachedCandidateEnd,
            "is-reached-projects-end": $setup.isReachedProjectsEnd,
            onSidebarOpen: $setup.sidebarOpen,
            onSidebarClosed: $setup.sidebarClosed,
            onGetMoreCandidatesTalkData: $setup.getMoreCandidateTalkData,
            onGetMoreProjectsTalkData: $setup.getMoreProjectTalkData
          }, null, 8 /* PROPS */, ["filtered-talks", "filtered-candidate-talks", "filtered-project-talks", "filtered-services-talks", "is-reached-candidate-end", "is-reached-projects-end"])
        ], 6 /* CLASS, STYLE */)
      ]))
    : _createCommentVNode("v-if", true)
}