import { Ref, ref } from 'vue';

const selectedTabForCandidatePage: Ref<string> = ref('overview');

export function useSelectedTab() {
  function setSelectedTabForCandidatePage(tab: string) {
    selectedTabForCandidatePage.value = tab;
  }

  return {
    selectedTabForCandidatePage,
    setSelectedTabForCandidatePage,
  };
}
