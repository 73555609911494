import { StrictUseAxiosReturn, useAxios } from '@vueuse/integrations/useAxios';
import { api, noSpinnerToken } from '@/config/AxiosConfig';
import { JobCandidate } from '@/model/JobCandidate.model';

export function useProjectCandidatesList() {
  const {
    data,
    isLoading,
    execute,
  }: StrictUseAxiosReturn<JobCandidate[]> & PromiseLike<StrictUseAxiosReturn<JobCandidate[]>> = useAxios<
    Array<JobCandidate>
  >('', api);

  function loadProjectCandidatesList(projectId: string) {
    execute(`v1/projects/${projectId}/applications?onlyEssentialData=true`);
  }

  function findProjectCandidates(findBy: string, noSpinner: boolean) {
    execute(
      `v1/projects/applications/find?onlyEssentialData=true&findBy=${findBy}${noSpinner ? '&' + noSpinnerToken : ''}`,
    );
  }

  return {
    data,
    isLoading,
    loadProjectCandidatesList,
    findProjectCandidates,
  };
}
