<template>
  <div
    class="size"
    :style="{
      background: color,
    }"
    :class="{
      sizeProject: isProjectPage,
    }"
  ></div>
</template>

<script>
  export default {
    name: 'ProjectColor',
    props: { color: String, page: Boolean },
    computed: {
      isProjectPage: function () {
        if (this.page === true) {
          return true;
        }
        return false;
      },
    },
  };
</script>

<style scoped lang="scss">
  .size {
    min-width: 5px;
    min-height: 5px;
    margin-right: 11px;
  }

  .sizeProject {
    min-width: 12px;
    min-height: 26px;
    margin-right: 0px;
  }
</style>
