<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <ion-menu
    v-show="$route.name !== 'Login'"
    auto-hide="false"
    class="main-menu"
    content-id="main-content"
    swipe-gesture
    type="overlay"
  >
    <ion-header :translucent="true">
      <ion-toolbar class="header__sidebar">
        <ion-buttons slot="start">
          <ion-menu-button></ion-menu-button>
        </ion-buttons>

        <img alt="EmmyScore Logo" class="logo" src="score/assets/emmyscore.svg" />
        <ion-text
          ><sub>{{ VERSION }}</sub>
        </ion-text>
      </ion-toolbar>

      <ion-toolbar class="header__sidebar header__sidebar-mobile">
        <div class="mobile-search">
          <emmy-searchbar v-if="isOpenSearch" class="mobile-search" />
        </div>
        <ion-buttons slot="end">
          <ion-menu-button v-if="!isOpenSearch"></ion-menu-button>
        </ion-buttons>

        <img
          v-if="!isOpenSearch"
          slot="start"
          alt="EmmyScore Logo"
          class="mobile-logo"
          src="score/assets/emmyscore.svg"
        />
        <ion-text
          ><sub>{{ VERSION }}</sub>
        </ion-text>
      </ion-toolbar>
    </ion-header>

    <ion-content class="sidebar">
      <ion-list id="nav-list" class="ion-no-padding" style="height: 100%">
        <ion-menu-toggle :auto-hide="false">
          <div class="vstack">
            <!-- DRAFT PROJECTS-->
            <ion-item
              :class="{ selected: isDraftSelected }"
              :detail="false"
              class="hydrated"
              lines="none"
              router-direction="root"
              router-link="/projects/draft"
              @click="toggleDraftSubmenu()"
            >
              <ion-icon :icon="createOutline" class="icon"></ion-icon>

              <ion-label class="desktop">{{ $t('sidebar.projectsDraft') }} </ion-label>
              <ion-label class="mobile">
                {{ $t('sidebar.projectsDraft') }}
              </ion-label>
            </ion-item>
            <ion-list
              v-if="
                (isOpenDraft && draftJobs && draftJobs.length > 0) || (isMobile && draftJobs && draftJobs.length > 0)
              "
              class="sidebar__projects"
            >
              <ion-item
                v-for="project in draftJobs.slice(0, 10)"
                :key="project.name"
                :class="{ active: isProjectSelected(project.id) }"
                :router-link="/project/ + project.id"
                class="wrap-text"
              >
                <ProjectColor :color="'#ffff'" />
                <div class="wrap-text">{{ project.name }}</div>
              </ion-item>
              <ion-item v-if="draftJobs.length > 10" :router-link="`/projects/draft`" class="wrap-text">
                <div class="wrap-text">...</div>
              </ion-item>
            </ion-list>
            <!-- END OF DRAFT PROJECTS-->

            <!-- RELEASE CANDIDATE PROJECTS -->
            <ion-item
              v-if="isEthTenant()"
              :class="{ selected: isReleaseCandidateSelected }"
              :detail="false"
              class="hydrated"
              lines="none"
              router-direction="root"
              router-link="/projects/releaseCandidate"
              @click="toggleReleaseCandidateMenu()"
            >
              <ion-icon :icon="checkmarkOutline" class="icon"></ion-icon>

              <ion-label class="desktop">{{ $t('sidebar.projectsReleaseCandidate') }} </ion-label>
              <ion-label class="mobile">
                {{ $t('sidebar.projectsReleaseCandidate') }}
              </ion-label>
            </ion-item>
            <ion-list
              v-if="
                (isOpenReleaseCandidate && releaseCandidateJobs && releaseCandidateJobs.length > 0) ||
                (isMobile && releaseCandidateJobs && releaseCandidateJobs.length > 0)
              "
              class="sidebar__projects"
            >
              <ion-item
                v-for="project in releaseCandidateJobs.slice(0, 10)"
                :key="project.name"
                :class="{ active: isProjectSelected(project.id) }"
                :router-link="/project/ + project.id"
                class="wrap-text"
              >
                <ProjectColor :color="'#ffff'" />
                <div class="wrap-text">{{ project.name }}</div>
              </ion-item>
              <ion-item v-if="releaseCandidateJobs.length > 10" :router-link="`/projects/draft`" class="wrap-text">
                <div class="wrap-text">...</div>
              </ion-item>
            </ion-list>
            <!-- END OF RELEASE CANDIDATE PROJECTS -->

            <!-- PUBLISHED PROJECTS -->
            <ion-item
              :class="{ selected: isPublishedSelected }"
              :detail="false"
              class="hydrated"
              lines="none"
              router-direction="root"
              router-link="/projects/open"
              @click="togglePublishedSubmenu()"
            >
              <ion-icon :icon="cloudDoneOutline" class="icon"></ion-icon>

              <ion-label class="desktop">{{ $t('sidebar.projectsPublished') }} </ion-label>
              <ion-label class="mobile">
                {{ $t('sidebar.projectsPublished') }}
              </ion-label>
            </ion-item>
            <ion-list
              v-if="
                (isOpenPublished && publishedJobs && publishedJobs.length > 0) ||
                (isMobile && publishedJobs && publishedJobs.length > 0)
              "
              class="sidebar__projects"
            >
              <ion-item
                v-for="project in publishedJobList.slice(0, 10)"
                :key="project.name"
                :class="{ active: isProjectSelected(project.id) }"
                :router-link="/project/ + project.id"
                class="wrap-text"
              >
                <ProjectColor :color="'#ffff'" />
                <div class="wrap-text">{{ project.name }}</div>
              </ion-item>
              <ion-item v-if="publishedJobs.length > 10" :router-link="`/projects/open`" class="wrap-text">
                <div class="wrap-text">...</div>
              </ion-item>
            </ion-list>
            <!-- END OF PUBLISHED PROJECTS -->

            <!-- CLOSED PROJECTS -->
            <ion-item
              :class="{ selected: isClosedSelected }"
              :detail="false"
              class="hydrated"
              lines="none"
              router-direction="root"
              router-link="/projects/closed"
              @click="toggleClosedSubmenu()"
            >
              <ion-icon :icon="closeOutline" class="icon"></ion-icon>

              <ion-label class="desktop">{{ $t('sidebar.projectsClosed') }} </ion-label>
              <ion-label class="mobile">
                {{ $t('sidebar.projectsClosed') }}
              </ion-label>
            </ion-item>
            <ion-list
              v-if="
                (isOpenClosed && closedJobs && closedJobs.length > 0) ||
                (isMobile && closedJobs && closedJobs.length > 0)
              "
              class="sidebar__projects"
            >
              <ion-item
                v-for="project in closedJobs.slice(0, 10)"
                :key="project.name"
                :class="{ active: isProjectSelected(project.id) }"
                :router-link="/project/ + project.id"
                class="wrap-text"
              >
                <ProjectColor :color="'#ffff'" />
                <div class="wrap-text">{{ project.name }}</div>
              </ion-item>
              <ion-item v-if="closedJobs.length > 10" :router-link="`/projects/closed`" class="wrap-text">
                <div class="wrap-text">...</div>
              </ion-item>
            </ion-list>
            <!-- END OF CLOSED PROJECTS -->

            <!--  TERMINATED PROJECTS -->
            <ion-item
              :class="{ selected: isFinishedSelected }"
              :detail="false"
              class="hydrated"
              lines="none"
              router-direction="root"
              router-link="/projects/finished"
              @click="toggleTerminatedSubmenu()"
            >
              <ion-icon :icon="archiveOutline" class="icon"></ion-icon>

              <ion-label class="desktop">{{ $t('sidebar.projectsTerminated') }} </ion-label>
              <ion-label class="mobile">
                {{ $t('sidebar.projectsTerminated') }}
              </ion-label>
            </ion-item>
            <ion-list
              v-if="
                (isOpenTerminated && terminatedJobs && terminatedJobs.length > 0) ||
                (isMobile && terminatedJobs && terminatedJobs.length > 0)
              "
              class="sidebar__projects"
            >
              <ion-item
                v-for="project in terminatedJobs.slice(0, 10)"
                :key="project.name"
                :class="{ active: isProjectSelected(project.id) }"
                :router-link="/project/ + project.id"
                class="wrap-text"
              >
                <ProjectColor :color="'#ffff'" />
                <div class="wrap-text">{{ project.name }}</div>
              </ion-item>
              <ion-item v-if="terminatedJobs.length > 10" :router-link="`/projects/finished`" class="wrap-text">
                <div class="wrap-text">...</div>
              </ion-item>
            </ion-list>
            <!-- END OF TERMINATED PROJECTS -->
            <!--  Talk -->
            <ion-item
              v-if="isTalkEnabled()"
              :class="{ selected: isTalkSelected }"
              :detail="false"
              class="hydrated"
              lines="none"
              router-direction="root"
              router-link="/emmytalk"
              @click="openTalk()"
            >
              <svg class="icon" height="26" viewBox="0 0 23 17" width="26" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M20.4124 0.115479H2.5874C1.40146 0.115479 0.431152 1.14449 0.431152 2.40216V14.5978C0.431152 15.4363 0.826465 16.1604 1.50928 16.5796C1.83271 16.7702 2.22803 16.8845 2.5874 16.8845C2.94678 16.8845 3.30615 16.7702 3.66553 16.5796L6.82803 14.6359C6.97178 14.5597 7.11553 14.5216 7.25928 14.5216H20.3765C21.5624 14.5216 22.5327 13.4926 22.5327 12.2349V2.40216C22.5687 1.14449 21.5983 0.115479 20.4124 0.115479ZM21.3108 12.2349C21.3108 12.7685 20.9155 13.1877 20.4124 13.1877H7.29521C6.93584 13.1877 6.54053 13.302 6.21709 13.4926L3.05459 15.4363C2.76709 15.5887 2.44365 15.5887 2.15615 15.4363C1.86865 15.2838 1.7249 14.9789 1.7249 14.6359V2.40216C1.7249 1.8686 2.12022 1.44938 2.62334 1.44938H20.4483C20.9515 1.44938 21.3468 1.8686 21.3468 2.40216V12.2349H21.3108Z"
                />
                <path
                  d="M16.064 4.68884H6.43276C6.07339 4.68884 5.78589 4.99373 5.78589 5.37485C5.78589 5.75596 6.07339 6.06085 6.43276 6.06085H16.1C16.4593 6.06085 16.7468 5.75596 16.7468 5.37485C16.7468 4.99373 16.4234 4.68884 16.064 4.68884Z"
                />
                <path
                  d="M14.5546 8.99545H6.43276C6.07339 8.99545 5.78589 9.30034 5.78589 9.68146C5.78589 10.0626 6.07339 10.3675 6.43276 10.3675H14.5906C14.95 10.3675 15.2375 10.0626 15.2375 9.68146C15.2375 9.30034 14.914 8.99545 14.5546 8.99545Z"
                />
              </svg>

              <ion-label class="desktop">{{ $t('sidebar.emmyTalk') }} </ion-label>
              <ion-label class="mobile">
                {{ $t('sidebar.emmyTalk') }}
              </ion-label>
            </ion-item>
            <!-- END of Talk -->

            <div class="divider"></div>
            <ion-item
              v-if="showCoreLink"
              class="hydrated emmycore"
              detail="false"
              lines="none"
              replace-true
              router-direction="root"
              @click="openEmmyCore()"
            >
              <div class="flex">
                <img class="solution-logo" src="score/assets/emmycore-logo.svg" />
                <img class="external-link" src="score/assets/external-link.svg" />
              </div>
            </ion-item>

            <ion-item
              v-if="showPortalLink"
              class="hydrated emmyportal"
              detail="false"
              lines="none"
              replace-true
              router-direction="root"
              @click="openEmmyPortal()"
            >
              <div class="flex">
                <img class="solution-logo" src="score/assets/emmyportal-logo.svg" />
                <img class="external-link" src="score/assets/external-link.svg" />
              </div>
            </ion-item>
          </div>
        </ion-menu-toggle>
      </ion-list>
    </ion-content>
  </ion-menu>
</template>

<script lang="ts" setup>
  import {
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonMenu,
    IonMenuButton,
    IonMenuToggle,
    IonText,
    IonToolbar,
    isPlatform,
  } from '@ionic/vue';
  import { archiveOutline, checkmarkOutline, closeOutline, cloudDoneOutline, createOutline } from 'ionicons/icons';
  import { computed, onMounted, ref, watch } from 'vue';
  import ProjectColor from '@/components/ui-elements/ProjectColor.vue';
  import EmmySearchbar from '@/components/EmmySearchbar.vue';
  import { useRouter } from 'vue-router';
  import { useProjectsListGlobalState } from '@/composables/useProjectsListGlobalStateNew';
  import { Job, JobState } from '@/model/Job.model';
  import { useProjectDetailsGlobalState } from '@/composables/useProjectDetailsGlobalState';
  import { useAuth } from '@/composables/useAuth';
  import { Permission } from '@/model/EmmyScorePermissions.model';
  import { useTenantDetailsGlobalState } from '@/composables/useTenantDetailsGlobalState';
  import { VERSION } from '@/build-info';

  const { checkUserHavePermission } = useAuth();

  const router = useRouter();

  const {
    draftJobs,
    releaseCandidateJobs,
    publishedJobs,
    publishedReviewingJobs,
    closedJobs,
    closedReviewingJobs,
    terminatedJobs,
  } = useProjectsListGlobalState();

  const { isEthTenant } = useTenantDetailsGlobalState();

  // global state (project) set by breadcrumbs
  const { data: project } = useProjectDetailsGlobalState();

  const isOpenDraft = ref(false);
  const isOpenReleaseCandidate = ref(false);
  const isOpenPublished = ref(false);
  const isOpenClosed = ref(false);
  const isOpenTerminated = ref(false);
  const isOpenTalk = ref(false);
  const isOpenSearch = ref(false);
  const showCoreLink = ref(false);
  const showPortalLink = ref(false);

  onMounted(async () => {
    if (router.currentRoute.value.name !== 'Login') {
      const haveAccessToCore = await checkUserHavePermission(Permission.EmmyCoreAccess);
      const haveAccessToPortal = await checkUserHavePermission(Permission.ViewerPortal);
      const emmyCoreLink = process.env.VUE_APP_EMMYCORE_LINK;
      const emmyPortalLink = process.env.VUE_APP_EMMYPORTAL_LINK;
      showCoreLink.value = !!emmyCoreLink && emmyCoreLink.length > 0 && haveAccessToCore;
      showPortalLink.value = !!emmyPortalLink && emmyPortalLink.length > 0 && haveAccessToPortal;
    }
  });

  const isMobile = computed(() => {
    if (isPlatform('tablet')) {
      return true;
    }
    if (isPlatform('mobile')) {
      return true;
    }
    return false;
  });

  const publishedJobList = computed(() => {
    const publishedJobsHandled = publishedJobs.value ?? [];
    if (!publishedReviewingJobs.value || publishedReviewingJobs.value.length === 0) {
      return publishedJobsHandled;
    }
    return publishedJobsHandled.concat(publishedReviewingJobs.value);
  });

  const closedJobList = computed(() => {
    if (!closedReviewingJobs.value || closedReviewingJobs.value.length === 0) {
      return closedJobs.value;
    }
    return closedJobs.value?.concat(closedReviewingJobs.value);
  });

  const toggleDraftSubmenu = () => {
    isOpenDraft.value = !isOpenDraft.value;
    collapseAllExceptDraft();
  };

  const toggleReleaseCandidateMenu = () => {
    isOpenReleaseCandidate.value = !isOpenReleaseCandidate.value;
    collapseAllExceptPublished();
  };

  const togglePublishedSubmenu = () => {
    isOpenPublished.value = !isOpenPublished.value;
    collapseAllExceptPublished();
  };

  const toggleClosedSubmenu = () => {
    isOpenClosed.value = !isOpenClosed.value;
    collapseAllExceptClosed();
  };

  const toggleTerminatedSubmenu = () => {
    isOpenTerminated.value = !isOpenTerminated.value;
    collapseAllExceptTerminated();
  };

  const openTalk = () => {
    collapseAll();
    isOpenTalk.value = !isOpenTalk.value;
  };

  const collapseAllExceptDraft = () => {
    isOpenReleaseCandidate.value = false;
    isOpenPublished.value = false;
    isOpenClosed.value = false;
    isOpenTerminated.value = false;
  };

  const collapseAllExceptReleaseCandidate = () => {
    isOpenDraft.value = false;
    isOpenPublished.value = false;
    isOpenClosed.value = false;
    isOpenTerminated.value = false;
  };

  const collapseAllExceptPublished = () => {
    isOpenDraft.value = false;
    isOpenReleaseCandidate.value = false;
    isOpenClosed.value = false;
    isOpenTerminated.value = false;
  };

  const collapseAllExceptClosed = () => {
    isOpenDraft.value = false;
    isOpenReleaseCandidate.value = false;
    isOpenPublished.value = false;
    isOpenTerminated.value = false;
  };

  const collapseAllExceptTerminated = () => {
    isOpenDraft.value = false;
    isOpenReleaseCandidate.value = false;
    isOpenPublished.value = false;
    isOpenClosed.value = false;
  };

  const collapseAll = () => {
    isOpenReleaseCandidate.value = false;
    isOpenPublished.value = false;
    isOpenClosed.value = false;
    isOpenTerminated.value = false;
    isOpenDraft.value = false;
  };

  const isDraftSelected = computed(() => {
    return isRoute('draft', draftJobs.value);
  });

  const isReleaseCandidateSelected = computed(() => {
    return isRoute('releaseCandidate', releaseCandidateJobs.value);
  });

  const isPublishedSelected = computed(() => {
    return isRoute('open', publishedJobs.value);
  });

  const isClosedSelected = computed(() => {
    return isRoute('closed', closedJobs.value);
  });

  const isFinishedSelected = computed(() => {
    return isRoute('finished', terminatedJobs.value);
  });

  const isTalkSelected = computed(() => {
    return isRoute('emmytalk', undefined);
  });

  const isProjectSelected = (projectId: string) => {
    return router.currentRoute.value.path.includes(projectId);
  };

  const isTalkEnabled = () => {
    return process.env.VUE_APP_ENABLE_TALK == 'true' ? true : false;
  };

  // watch if route matches one of job states and mark corresponding submenu
  watch(router.currentRoute, () => {
    if (isRoute('draft', draftJobs.value)) {
      isOpenDraft.value = true;
      collapseAllExceptDraft();
    }

    if (isRoute('releaseCandidate', releaseCandidateJobs.value)) {
      isOpenReleaseCandidate.value = true;
      collapseAllExceptReleaseCandidate();
    }

    if (isRoute('open', publishedJobs.value)) {
      isOpenPublished.value = true;
      collapseAllExceptPublished();
    }

    if (isRoute('closed', closedJobs.value)) {
      isOpenClosed.value = true;
      collapseAllExceptClosed();
    }

    if (isRoute('finished', terminatedJobs.value)) {
      isOpenTerminated.value = true;
      collapseAllExceptTerminated();
    }
  });

  const isRoute = (routePath: string, jobs: Job[] | undefined) => {
    const matchesRoute = router.currentRoute.value.path.includes(routePath);
    const matchesProjectId = jobs?.find(j => router.currentRoute.value.params.projectId === j.id);
    return matchesRoute || matchesProjectId;
  };

  // we get project from global composable state (loaded by project details or candidate details page)
  // we need this if we access with direct link app, we check loaded project in global state and select corresponding sidebar submenu
  watch(project, () => {
    if (project.value && project.value.state === JobState.Draft) {
      isOpenDraft.value = true;
      collapseAllExceptDraft();
    }

    if (project.value && project.value.state === JobState.ReleaseCandidate) {
      isOpenReleaseCandidate.value = true;
      collapseAllExceptReleaseCandidate();
    }

    if (project.value && project.value.state === JobState.Published) {
      isOpenPublished.value = true;
      collapseAllExceptPublished();
    }

    if (project.value && project.value.state === JobState.Closed) {
      isOpenClosed.value = true;
      collapseAllExceptClosed();
    }

    if (project.value && project.value.state === JobState.Terminated) {
      isOpenTerminated.value = true;
      collapseAllExceptTerminated();
    }
  });

  const openEmmyCore = () => {
    openLink(process.env.VUE_APP_EMMYCORE_LINK);
  };

  const openEmmyPortal = () => {
    openLink(process.env.VUE_APP_EMMYPORTAL_LINK);
  };
  const openLink = (link: string | undefined) => {
    if (link) {
      window.open(link, '_blank');
    }
  };
</script>

<style lang="scss" scoped>
  .main-menu {
    /**
   * The dragable sidebar caused the text to get highlighted,
   * this will disable selection for the menu.
   */
    user-select: none;
  }

  .sidebar__account {
    display: flex;
    align-content: center;
    justify-content: center;
    width: 100%;
  }

  .logo {
    height: 28px;
    margin-top: 2px;
    margin-left: 16px;
  }

  @media (min-width: 900px) {
    ion-menu {
      .header__sidebar {
        display: flex;
      }

      .header__sidebar-mobile {
        display: none;
      }
    }

    .mobile {
      display: none;
    }
  }

  @media (max-width: 899px) {
    .emmytalk {
      display: none;
    }

    ion-menu {
      --width: 100% !important;

      .sidebar::part(background) {
        background: var(--background-secondary);
      }

      .header__sidebar img {
        max-height: 40px;
        text-align: center;
      }

      .sidebar ion-list.sidebar__projects {
        background: #ffffff;
        color: var(--font-secondary);

        .active {
          color: var(--font-sidebar-selected);
        }
      }

      .sidebar ion-item {
        padding-left: 16px;
        padding-right: 16px;
      }

      .sidebar ion-item.selected {
        --color: var(--font-sidebar-selected);
      }

      .sidebar ion-item.selected ion-label {
        color: var(--font-white);

        svg {
          path {
            fill: blue;
          }
        }
      }

      .sidebar ion-list.sidebar__projects ion-item.active::part(native) {
        color: var(--font-secondary) !important;
      }

      .header__sidebar {
        display: none;
      }

      .header__sidebar-mobile {
        display: flex;
        justify-content: space-between;
        height: 55px;
      }

      ion-buttons {
        margin-top: 4px;
      }

      .desktop {
        display: none;
      }
    }

    .mobile-logo {
      margin-left: 16px;
      height: 24px;
    }

    .mobile-search {
      margin-left: 16px;

      .searchbar-input-container {
        text-align: left;
      }
    }

    .mobile-search-cancel {
      cursor: pointer;
      margin-left: 10px;
      margin-right: 10px;
      display: block;
      font-weight: bold;
      font-size: 16px;
      color: var(--font-title);
    }

    .mobile {
      display: block;
    }

    .mobile-emmybi {
      display: none;
    }
  }

  .sidebar::part(background) {
    background: var(--background-primary);
  }

  .sidebar::part(scroll) {
    padding: 0;
  }

  .sidebar ion-list:not(.sidebar__projects),
  .sidebar ion-item {
    background: none;
    cursor: pointer;
  }

  .sidebar ion-item {
    border-radius: 0;
    cursor: pointer !important;

    &::part(native) {
      background: none;
    }

    .icon {
      margin-inline-end: 18px;
      color: #ffffff;
      margin-top: 0;
      fill: #ffffff;

      @media (max-width: 900px) {
        color: #ffffff;
      }
    }

    ion-label {
      color: #81a6ba;
      font-size: 1.3rem;
      font-weight: 600;
      cursor: pointer;
    }

    @media (min-width: 900px) {
      ion-label {
        color: var(--font-white);
      }
    }

    &.selected {
      background: var(--background-sidebar-selected);

      path {
        fill: var(--font-sidebar-selected);
        opacity: 1;
      }

      @media (max-width: 900px) {
        path {
          fill: var(--font-sidebar-selected);
        }
      }

      ion-label {
        color: var(--font-sidebar-selected);
        cursor: pointer;
      }
    }
  }

  .sidebar ion-list.sidebar__projects {
    background: var(--background-dark);

    ion-item {
      cursor: pointer;
      --inner-border-width: 0;
      --min-height: 0;
      --padding-top: 10px;
      --padding-bottom: 10px;

      &.active::part(native) {
        color: #ffffff;
        font-weight: 600;
        cursor: pointer;
      }
    }

    ion-item::part(native) {
      color: #d2d2d2;
      font-size: 1.3rem;
      cursor: pointer;
    }

    @media (max-width: 899px) {
      ion-item::part(native) {
        color: #7e7e7e;
        font-size: 1.3rem;
        cursor: pointer;
      }
    }
  }

  .wrap-text {
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  ion-header {
    image-rendering: -webkit-optimize-contrast;
  }

  ion-button {
    position: relative;
    margin: 0;
    --padding-end: 0;
    --padding-top: 0;
    --padding-start: 0;
    --background: transparent;

    svg {
      margin-right: 10px;
      fill: #9a9eb3;
    }
  }

  .sidebar__account {
    margin-top: 36px;
    margin-bottom: 36px;
    align-items: center;
  }

  .arrow {
    width: 16px;
    height: 16px;
  }

  .title {
    margin-left: 10px;
    margin-right: 10px;
    display: block;
    font-weight: bold;
    font-size: 16px;
    color: var(--font-title);
  }

  .mobile-avatar {
    margin-left: 10px;
  }

  .solution-logo {
    height: 24px;
  }

  .emmycore,
  .emmyportal {
    background-color: rgba(#fff, 1) !important;
  }

  .flex {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .external-link {
    width: 20px;
    height: 20px;
    opacity: 0.3;
    transition: all 0.1s ease-in-out;
  }

  .emmycore:hover .external-link {
    opacity: 0.9;
  }

  .emmyportal:hover .external-link {
    opacity: 0.9;
  }

  .vstack {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .divider {
    flex-grow: 1;
  }
</style>
