import { initializeApp } from 'firebase/app';
import { getMessaging, onMessage } from 'firebase/messaging';

export const firebaseConfig = JSON.parse(atob(process.env.VUE_APP_FIREBASE_CONFIG_JSON_BASE64 ?? '')) as {
  apiKey: string;
  authDomain: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
  vapidKey: string;
};

export const firebaseApp = initializeApp(firebaseConfig);
export const firebaseMessaging = getMessaging(firebaseApp);

onMessage(firebaseMessaging, payload => {
  console.log('Message received. ', payload);
});
