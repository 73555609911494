<template>
  <div class="chat-button">
    <img src="@/assets/chat.svg" :alt="$t('emmyTalk.startTalk')" />
  </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
  .chat-button {
    position: absolute;
    top: 5px;
  }

  svg {
    path:first-child {
      fill: var(--circle-background-emmytalk);
    }

    path:not(:first-child) {
      fill: var(--circle-color-emmytalk);
    }
  }
</style>
