<template>
  <div :class="isLoadingFirst ? 'blur' : 'noBlur'">
    <ion-list
      v-if="activeSegment == 'talk_projects' && filteredProjectTalks"
      ref="projectElement"
      class="scroll-fix emmytalk-fullscreen"
      :class="{
        'max-height': !isOverlay && !isEmmyTalk,
        'max-height-emmyTalk': !isOverlay && isEmmyTalk,
      }"
    >
      <ion-item v-for="projectData in filteredProjectTalks" :key="`project_message_overview-${projectData.id}`">
        <project-message-overview :project-data="projectData" :is-overlay="isOverlay" />
      </ion-item>
    </ion-list>

    <ion-list
      v-if="activeSegment == 'talk_all' && filteredTalks"
      :key="`talk_overview_list-${filteredTalks.length}`"
      class="scroll-fix emmytalk-fullscreen"
      :class="{
        'max-height': !isOverlay,
      }"
    >
      <ion-item
        v-for="talk in filteredTalks"
        :key="`talk_overview_${talk._id}${talk.lastMessage ? talk.lastMessage._id : ''}`"
      >
        <emmy-talk-overview-card
          v-if="accessToken.length > 0 && idToken.length > 0"
          :key="`talk_overview_${talk._id}_${new Date().getTime()}`"
          :locale="locale"
          :access-token="accessToken"
          :id-token="idToken"
          :base-api-url="talkApiUrl"
          :caller-reference-object="getTalkReferenceObjectJSON(talk, isOverlay)"
          :talk-id="talk._id"
          :tenant-id="tenantId()"
          :talk="talk"
          show-inactive="false"
          style="width: 100%; height: 90%; padding-right: 8px"
        ></emmy-talk-overview-card>
      </ion-item>
    </ion-list>

    <ion-list
      v-if="activeSegment == 'talk_candidates' && filteredCandidateTalks"
      ref="candidateElement"
      class="scroll-fix emmytalk-fullscreen"
      :class="{
        'max-height': !isOverlay,
      }"
    >
      <ion-item v-for="candidateTalk in filteredCandidateTalks" :key="`candidate_overview_${candidateTalk.id}`">
        <candidate-message-overview
          :candidate-data="candidateTalk"
          :is-overlay="isOverlay"
          :show-left-border="true"
          :show-project-name="true"
        />
      </ion-item>
    </ion-list>
    <ion-list
      v-if="activeSegment == 'talk_services' && filteredServicesTalks"
      ref="projectElement"
      class="scroll-fix emmytalk-fullscreen"
      :class="{
        'max-height': !isOverlay,
      }"
    >
      <ion-item v-for="servicesTalk in filteredServicesTalks" :key="`services_overview_${servicesTalk.id}`">
        <project-message-overview
          :project-data="servicesTalk"
          :is-overlay="isOverlay"
          :is-recruitment-services="true"
        />
      </ion-item>
    </ion-list>
  </div>
  <ion-spinner v-if="isLoadingFirst" name="crescent" class="spin-center"></ion-spinner>
  <ion-spinner v-if="isLoading" name="crescent" class="spin"></ion-spinner>
</template>

<script setup lang="ts">
  import { IonList, IonItem, IonSpinner } from '@ionic/vue';
  import CandidateMessageOverview from './messageOverviews/CandidateMessageOverview.vue';
  import ProjectMessageOverview from './messageOverviews/ProjectMessageOverview.vue';

  import { computed, watch, Ref, ref, onMounted } from 'vue';
  import { useRoute } from 'vue-router';
  import { useEmmyTalk } from '../composables/useEmmyTalk';
  import { Talk } from '@/views/talk/model/Talk.model';
  import { useI18n } from 'vue-i18n';
  import { Auth } from 'aws-amplify';
  import { CandidateData } from '@/views/talk/model/CandidateData';
  import { ProjectData } from '@/views/talk/model/ProjectData';
  import { useWebComponentsGlobalState } from '@/composables/useWebComponentsGlobalState';
  import { RecruitmentServicesData } from '@/views/talk/model/RecruitmentServicesData';
  import { useInfiniteScroll } from '@vueuse/core';

  const { talksSegmentSelected, isLoading, isLoadingFirst } = useWebComponentsGlobalState();
  const route = useRoute();
  const { locale } = useI18n();
  const { getTalkReferenceObject } = useEmmyTalk();

  const accessToken: Ref<string> = ref('');
  const idToken: Ref<string> = ref('');

  const projectElement = ref<HTMLElement | null>(null);
  const candidateElement = ref<HTMLElement | null>(null);

  const delayTime = 1000;
  let isCandidateScrollEnabled = true;
  let isProjectScrollEnabled = true;

  const emit = defineEmits<{
    (e: 'getMoreCandidatesTalkData'): void;
    (e: 'getMoreProjectsTalkData'): void;
  }>();

  useInfiniteScroll(
    candidateElement,
    async () => {
      if (!props.isReachedCandidateEnd && isCandidateScrollEnabled) {
        isCandidateScrollEnabled = false;
        emit('getMoreCandidatesTalkData');
        setTimeout(() => {
          isCandidateScrollEnabled = true;
        }, delayTime);
      }
    },
    { distance: 10 },
  );

  useInfiniteScroll(
    projectElement,
    () => {
      if (!props.isReachedProjectsEnd && isProjectScrollEnabled) {
        isProjectScrollEnabled = false;
        emit('getMoreProjectsTalkData');
        setTimeout(() => {
          isProjectScrollEnabled = true;
        }, delayTime);
      }
    },
    { distance: 10 },
  );

  Auth.currentSession().then(data => {
    accessToken.value = data.getAccessToken().getJwtToken();
    idToken.value = data.getIdToken().getJwtToken();
  });

  const talkApiUrl = process.env.VUE_APP_WEB_COMPONENT_TALK_API_URL ?? '';
  const activeSegment: Ref<string> = ref('talk_all');

  const props = defineProps<{
    isOverlay: boolean;
    filteredTalks?: Talk[] | undefined;
    filteredCandidateTalks?: CandidateData[] | undefined;
    filteredProjectTalks?: ProjectData[] | undefined;
    filteredServicesTalks?: RecruitmentServicesData[] | undefined;
    isReachedCandidateEnd: boolean;
    isReachedProjectsEnd: boolean;
  }>();

  onMounted(() => {
    activeSegment.value = talksSegmentSelected.value;
  });

  const isEmmyTalk = computed(() => {
    return route.name === 'EmmyTalk';
  });

  watch(talksSegmentSelected, () => {
    activeSegment.value = talksSegmentSelected.value;
  });

  const getTalkReferenceObjectJSON = (talk: object, isOverlay: boolean) => {
    return JSON.stringify(getTalkReferenceObject(talk, isOverlay));
  };

  const tenantId = () => {
    return process.env.VUE_APP_TENANT_ID ?? '';
  };
</script>

<style scoped lang="scss">
  ion-list::-webkit-scrollbar {
    width: 8px;
  }

  ion-list::-webkit-scrollbar-track {
    background-color: transparent;
    margin-right: 60px;
    border-radius: 10px;
  }

  ion-list::-webkit-scrollbar-thumb {
    background-color: #ededf0;
    opacity: 0.3;
    border-radius: 10px;
    height: 140px;
  }

  ion-list {
    margin-top: 3px;
    padding: 0 0 30px;
    background: transparent;
    height: calc(80vh - 200px);
    max-height: 80vh;

    ion-item {
      --background: transparent;
      --inner-border-width: 0;
    }
  }

  @media (max-width: 899px) {
    ion-list {
      height: calc(100vh - 560px);
      max-height: 100vh;
    }

    .emmytalk-fullscreen {
      min-height: calc(100vh - 170px);
      max-height: calc(100vh - 170px);
    }
  }

  /*.max-height {
height: 651px;
// height: calc(100vh - 367px);
}*/

  .scroll-fix {
    overflow-y: scroll;
  }

  .spin {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }

  .spin-center {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .blur {
    filter: blur(1.5rem);
  }
</style>
