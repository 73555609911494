<template>
  <ion-searchbar
    v-if="
      checkPermission(Permissions.FindJobs) ||
      checkAllPermissions(Permissions.FindParticipations, Permissions.SeePersonalCandidateDetails)
    "
    debounce="500"
    :placeholder="`${$t('search.search')}`"
    inputmode="search"
    type="search"
    enterkeyhint="search"
    autocomplete="off"
    autocorrect="off"
    @ionChange="handleSearch($event.target.value)"
  >
  </ion-searchbar>

  <teleport to="body">
    <div v-if="isOpen" class="search-results">
      <ion-card>
        <ion-card-content>
          <ion-card-header>{{ $t('search.projects') }}</ion-card-header>
          <div v-if="!isLoadingJobsInProgress">
            <ion-item
              v-for="result in jobs"
              :key="result.id"
              :router-link="`/project/${result.id}`"
              @click="handleClick()"
            >
              {{ result.name }}
            </ion-item>

            <ion-item v-if="!jobs?.length" disabled>
              {{ $t('search.projectNotFound') }}
            </ion-item>
          </div>
          <div v-else>
            <ion-item>
              <ion-spinner name="crescent" color="primary" />
            </ion-item>
          </div>
          <ion-card-header class="ion-margin-top">{{ $t('search.candidates') }}</ion-card-header>
          <div v-if="!isLoadingCandidatesInProgress">
            <div v-for="result in jobCandidatesList" :key="result.id">
              <ion-item :router-link="`/project/${result.jobOpeningId}/candidates/${result.id}`" @click="handleClick">
                <!--<AvatarImg :documents="result.documents" size="64" />-->
                {{ result.contactInfo?.fullName }}
                <ion-text class="ion-padding-start" color="medium">{{ result.jobOpeningName }} </ion-text>
              </ion-item>
            </div>

            <ion-item v-if="!jobCandidatesList?.length" disabled>
              {{ $t('search.candidateNotFound') }}
            </ion-item>
          </div>
          <div v-else>
            <ion-item>
              <ion-spinner name="crescent" color="primary" />
            </ion-item>
          </div>
        </ion-card-content>
      </ion-card>
    </div>
  </teleport>
</template>

<script setup lang="ts">
  import { IonSearchbar, IonCard, IonCardHeader, IonCardContent, IonItem, IonText, IonSpinner } from '@ionic/vue';

  import { ref, watch } from 'vue';
  import { useProjectCandidatesList } from '@/views/candidate/composables/useJobCandidatesList';
  import { useProjectsList } from '@/composables/useProjectsList';
  import { useAuthGlobalState } from '@/composables/useAuthGlobal';
  import { Permissions } from '@/model/permission.model';

  function keydownListener(e: KeyboardEvent) {
    if (e.key === 'Escape') {
      handleClick();
    }
  }

  const { data: jobCandidatesList, isLoading: isLoadingCandidates, findProjectCandidates } = useProjectCandidatesList();

  const { data: jobs, isLoading: isLoadingJobs, findJobs } = useProjectsList();

  const { checkPermission, checkAllPermissions } = useAuthGlobalState();

  const isOpen = ref(false);

  const isLoadingCandidatesInProgress = ref(false);
  const isLoadingJobsInProgress = ref(false);

  const handleSearch = (data: string | null | undefined) => {
    if (data) {
      isLoadingCandidatesInProgress.value = true;
      isLoadingJobsInProgress.value = true;

      findJobs(data, true);
      findProjectCandidates(data, true);
    }

    const open = data ? data.length > 0 : false;
    isOpen.value = open;

    if (open) {
      document.addEventListener('keydown', keydownListener);
    }
  };

  watch(isLoadingCandidates, () => {
    if (isLoadingCandidates.value === false && isLoadingCandidatesInProgress.value === true) {
      isLoadingCandidatesInProgress.value = false;
    }
  });

  watch(isLoadingJobs, () => {
    if (isLoadingJobs.value === false && isLoadingJobsInProgress.value === true) {
      isLoadingJobsInProgress.value = false;
    }
  });

  const handleClick = () => {
    isOpen.value = false;
    document.removeEventListener('keydown', keydownListener);
  };
</script>

<style scoped lang="scss">
  ion-searchbar {
    border: 2px solid #ededf0;
    border-radius: 100px;
    --box-shadow: none;
    --border-radius: 50px;
    text-align: left !important;
  }

  ion-card-header {
    font-size: 1.8rem;
    font-weight: 600;
  }

  .search-results {
    background: rgba(23, 77, 121, 0.5);
    height: 100%;
    --min-width: 100px;
    --min-height: 100px;
    --max-width: auto;
    --height: auto;
    left: 0;
    right: 0;
    top: 70px;
    position: fixed;
    color: var(--ion-text-color, #000);
    z-index: 200003;
    padding-right: 30%;
    padding-left: 30%;
    overflow-y: auto;
    bottom: 20px;
    padding-bottom: 80px;

    ion-card {
      margin-top: 20px;
      border-top: none;
      border-radius: 10px;
      max-height: 85%;
      display: flex;
      flex-direction: column;
    }

    ion-card-content {
      overflow-y: auto;
    }

    ion-avatar {
      margin-right: 18px;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    ion-item {
      cursor: pointer;
    }
  }

  @media (min-width: 900px) {
    ion-searchbar {
      margin-right: 16px;
    }
  }

  @media (max-width: 899px) {
    .search-results {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .search-icon {
    margin-left: 10px;
  }
</style>
