import { createRouter, createWebHistory } from '@ionic/vue-router';
import { Auth } from 'aws-amplify';
import { RouteRecordRaw } from 'vue-router';
import { useSelectedTab } from '@/views/candidate/composables/useSelectedTab';
import { useAuthGlobalState } from '@/composables/useAuthGlobal';
import { Permissions } from '@/model/permission.model';
import { usePushNotifications } from '@/composables/usePushNotifications';
import { App } from 'vue';
import { useWebComponentsGlobalState } from '@/composables/useWebComponentsGlobalState';

const { loadUserProfile, checkPermission } = useAuthGlobalState();
const { setSelectedTabForCandidatePage } = useSelectedTab();

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/projects/open',
  },
  {
    path: '/projects',
    redirect: '/projects/open',
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login/LoginPage.vue'),
  },
  {
    path: '/projects/:status',
    name: 'Projects',
    component: () => import('../views/project/ProjectsPage.vue'),
  },
  {
    path: '/project/:projectId',
    name: 'ProjectDetails',
    component: () => import('../views/project/ProjectDetailsPage.vue'),
    props: route => ({ projectId: route.params.projectId ? route.params.projectId : undefined }),
  },
  {
    path: '/project/:projectId/edit',
    name: 'ProjectEdit',
    component: () => import('../views/project/ProjectEditPage.vue'),
    props: route => ({ projectId: route.params.projectId ? route.params.projectId : undefined }),
  },
  {
    path: '/project/:projectId/invite',
    name: 'ProjectInviteCandidates',
    component: () => import('../views/project/ProjectInviteCandidatesPage.vue'),
    props: route => ({ projectId: route.params.projectId ? route.params.projectId : undefined }),
  },
  {
    path: '/project/:projectId/candidates/:candidateId',
    name: 'ProjectsCandidateDetails',
    component: () => import('../views/candidate/CandidatePage.vue'),
    props: route => ({ filter: route.query.filter ? route.query.filter : '' }),
  },
  {
    path: '/project/:projectId/candidates/compare',
    name: 'CompareSelectedCandidates',
    component: () => import('../views/project/components/candidatesList/CandidatesListCompare.vue'),
    props: route => ({
      ids: route.query.ids ? route.query.ids : [],
      projectId: route.params.projectId ? route.params.projectId : undefined,
    }),
  },
  {
    path: '/developer',
    name: 'Developer',
    component: () => import('../views/developer/DeveloperPage.vue'),
  },
  {
    path: '/project/:projectId/advertise/:language',
    name: 'Advertisment',
    component: () => import('../views/advertise/AdPage.vue'),
  },
  {
    path: '/campaigns',
    name: 'Ad campaigns',
    component: () => import('../views/advertise/AdCampaigns.vue'),
  },
  {
    path: '/settings',
    name: 'TenantSettings',
    component: () => import('../views/settings/SettingsPage.vue'),
  },
  {
    path: '/emmytalk',
    name: 'ScoreTalk',
    component: () => import('../views/talk/ScoreTalk.vue'),
  },
  {
    path: '/templates',
    name: 'SettingsTemplates',
    component: () => import('../views/templates/TemplatePage.vue'),
  },
  {
    path: '/templates/:templateId',
    name: 'SettingsTemplatesDetails',
    component: () => import('../views/templates/TemplateDetailsPage.vue'),
    props: route => ({ templateId: route.params.templateId ?? undefined }),
  },
  {
    path: '/templates/:templateId/edit',
    name: 'ProjectSettingsTemplatesEdit',
    component: () => import('../views/templates/TemplateEditPage.vue'),
    props: route => ({ templateId: route.params.templateId ? route.params.templateId : undefined }),
  },
  {
    path: '/review/:token',
    name: 'Review',
    component: () => import('../views/review/ReviewPage.vue'),
    props: route => ({ token: route.params.token ? route.params.token : undefined }),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  let hasAdminConsoleAccess = false;
  let isAuthenticated = false;

  try {
    isAuthenticated = !!(await Auth.currentAuthenticatedUser());
    if (isAuthenticated) {
      await loadUserProfile();
      isAuthenticated = checkPermission(Permissions.emmyscore_viewer);
      hasAdminConsoleAccess = checkPermission(Permissions.EditTenant);
    }
  } catch {
    isAuthenticated = false;
  }

  if (!isAuthenticated && to.name === 'Review') {
    next();
    return;
  }

  if (!isAuthenticated && to.path !== '/login') {
    next({ path: '/login' });
    return;
  }

  if (isAuthenticated && to.path === '/login') {
    next({ path: '/' });
    return;
  }

  if (!hasAdminConsoleAccess && to.path === '/settings') {
    next({ path: '/' });
    return;
  } else {
    next();
  }

  if (to.name === 'ProjectsCandidateDetails') {
    setSelectedTabForCandidatePage('overview');
  }
});

export function onRouterReady(app: App) {
  const { emmyTalkInit } = useWebComponentsGlobalState();

  router.isReady().then(async () => {
    app.mount('#app');

    if (process.env.VUE_APP_ENABLE_TALK ?? false) {
      if (process.env.VUE_APP_ENABLE_TALK == 'true') {
        const talkUrl = process.env.VUE_APP_WEB_COMPONENT_TALK_URL ?? '';
        if (talkUrl.length > 0) {
          import(process.env.VUE_APP_WEB_COMPONENT_TALK_URL ?? '')
            .then(({ register }) => {
              register();
              emmyTalkInit();
            })
            .catch(error => {
              console.error('Failed to import the talk web component:', error);
            });
        }
      }
    }

    await usePushNotifications().registerDevice();
  });
}

export default router;
