<script setup>
  import ResponsiveSlot from './ResponsiveSlot.vue';
  import { computed, onBeforeMount, ref } from 'vue';

  const breakpoints = ref([
    { name: 'mobile', range: { max: 1250 } },
    { name: 'desktop', range: { min: 1251 } },
  ]);

  const props = defineProps({
    /**
     * Holds either an `Array<{ stage: String, inStateSince: String, id: string }> or a single `String` with the current step.
     */
    display: {
      required: true,
      type: [Array, String],
    },

    steps: {
      type: Array,
      default: () => ['unprocessed', 'longList', 'shortList', 'rejected'],
    },

    i18nPathPrefix: {
      type: String,
      default: 'emmyTalk.funnelingStage',
    },

    windowBased: {
      type: Boolean,
      default: false,
    },
  });

  onBeforeMount(() => {
    if (typeof props.display === 'string') {
      if (!props.steps.includes(props.display)) {
        throw new Error(`Invalid step name "${props.display}". Valid steps are: ${props.steps.join(', ')}`);
      }
    }
  });

  /**
   * Generates a string for the timestamp passed to it.
   *
   * @param {String} timestamp
   * @returns {String}
   */
  function generateTimestampString() {
    if (!isArray.value) return '';

    const timestamp = props.display[0]?.inStateSince;

    if (!timestamp) {
      throw new Error('Timestamp is not defined.');
    }

    return new Date(timestamp).toLocaleString('en-GB', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });
  }

  /**
   * Will return the i18n id for the step at the given index.
   *
   * @param {number} index The index of the step
   */
  function getI18nId(index) {
    if (index == -1) return 'emmyTalk.funnelingStage.unknown';
    return `${props.i18nPathPrefix}.${props.steps[index]}`;
  }

  /**
   * Denotes wether the StatusBar is displaying a bar with timestamps or a general bar without timestamps and just the
   * different step names.
   */
  const isArray = computed(() => {
    return Array.isArray(props.display);
  });

  /**
   * Returns the index of the current step
   */
  const currentIndex = computed(() => {
    return isArray.value ? props.steps.indexOf(props.display[0].stage) : props.steps.indexOf(props.display);
  });
</script>

<template>
  <responsive-slot :breakpoints="breakpoints" :window-based="windowBased">
    <template #mobile>
      <div class="path">
        <div class="path-element current">
          <div class="vstack">
            <p class="step-name">
              {{ $t(getI18nId(currentIndex)) }}
            </p>
            <p v-if="isArray" class="date">
              {{ generateTimestampString(currentIndex) }}
            </p>
          </div>
        </div>
      </div>
    </template>
    <template #desktop>
      <div class="path desktop">
        <div class="path-element desktop current">
          <div class="vstack">
            <p class="step-name desktop">
              {{ $t(getI18nId(n - 1)) }}
            </p>
            <p v-if="isArray && n - 1 <= currentIndex" class="date desktop">
              {{ generateTimestampString(n - 1) }}
            </p>
          </div>
        </div>
      </div>
    </template>
  </responsive-slot>
</template>

<style lang="scss" scoped>
  $heightMobile: 28px;
  $heightDesktop: 34px;

  .vstack {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4px 0;
    z-index: 0;
  }

  .path {
    display: flex;
    flex-direction: row;
    box-shadow: 0 0 0 1px #ededf0;
    background-color: #fff;
    cursor: default;
    margin-bottom: 1px; // Prevents the shadow from being cut off

    height: $heightMobile;
    border-radius: calc($heightMobile / 2);

    &.desktop {
      justify-content: space-between;

      height: $heightDesktop;
      border-radius: calc($heightDesktop / 2);
    }
  }

  .path-element {
    display: flex;
    align-items: center;
    justify-content: center;

    height: $heightMobile;
    padding: 0 calc($heightMobile / 3);
    border-radius: calc($heightMobile / 2);

    &:first-child {
      /* Affects .current */
      margin-left: 0;
    }

    &.desktop {
      width: 100%;
      text-align: center;

      height: $heightDesktop;
      padding: 0 calc($heightDesktop / 3 + 18px);
      border-radius: calc($heightDesktop / 2);

      box-shadow: 0 0 0 2px #ededf0;

      // border: 1px solid blueviolet;

      &:not(:first-of-type) {
        margin-left: calc(-1 * $heightDesktop);
        padding-left: calc($heightDesktop + 20px);

        // border: 1px solid salmon;
      }
    }
  }

  .current {
    font-size: small;
    color: var(--status-current-text-color);
    z-index: 2;
    flex: 1;
    background-color: var(--status-current-background-color);
    flex-shrink: 2;

    margin-left: calc(-1 * $heightMobile);

    &.desktop {
      flex: auto;
    }
  }

  p {
    display: block;
    padding: 0;
    margin: 0;
    width: 100%;
    white-space: nowrap;
    text-overflow: clip;
    text-align: center;

    line-height: calc(($heightMobile - 4px) / 2);

    &.desktop {
      line-height: calc(($heightDesktop - 4px) / 3);
    }
  }

  .step-name {
    font-weight: medium;

    &.desktop {
      font-weight: 600;
      font-size: 14px;
    }
  }

  .date {
    margin-top: 2px;
    font-size: x-small;
  }
</style>
