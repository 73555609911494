import { i18n } from '@/config/VueI18n';
import { loadingController, toastController } from '@ionic/vue';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { warningOutline } from 'ionicons/icons';

let creatingSpinner = false;
const spinnerStack: string[] = [];
export const noSpinnerToken = 'noSpinner';

interface ApiError {
  statusCode: number;
  message: string;
  timestamp: string;
  path: string;
  errorDetails: ApiErrorDetails;
}

interface ApiErrorDetails {
  errorCode: string;
  message: string;
}

// configure axios
const api = axios.create({
  baseURL: process.env.VUE_APP_API,
  headers: {
    'Content-type': 'application/json',
  },
});

//console.log( 'ENV:', process.env.VUE_APP_API);
//console.log( 'ENV:', process.env.VUE_APP_ETHZ_INTEGRATOR_API);

const username = process.env.VUE_APP_ETHZ_INTEGRATOR_API_USER;
const password = process.env.VUE_APP_ETHZ_INTEGRATOR_API_PASS;

const encodedCredentials = btoa(`${username}:${password}`);

const integratorApi = axios.create({
  baseURL: process.env.VUE_APP_ETHZ_INTEGRATOR_API,
  headers: {
    'Content-type': 'application/json',
    Authorization: `Basic ${encodedCredentials}`,
  },
});

let loading: HTMLIonLoadingElement | undefined = undefined;
api.interceptors.request.use(
  async request => {
    if (request.url) {
      if (!(request.url?.indexOf(noSpinnerToken) > 0)) {
        increaseSpinnerStack(request.url);
        // show loading bar
        if ((!loading || spinnerStack.length === 1) && !creatingSpinner) {
          creatingSpinner = true;
          loading = await loadingController.create({
            message: i18n.global.t('general.loadingPleaseWait'),
            animated: true,
            keyboardClose: false,
            id: `${Date.now()}`,
          });
          creatingSpinner = false;
        }

        showLoading();
      }

      // add auth header with jwt if account is logged in and request is to the api url
      const currentSession = await Auth.currentSession();
      const accessToken = currentSession.getAccessToken().getJwtToken();
      const idToken = currentSession.getIdToken().getJwtToken();
      const talkApiUrl = process.env.VUE_APP_WEB_COMPONENT_TALK_API_URL ?? 'not defined';
      const isTalkRequest = request.url.indexOf(talkApiUrl) >= 0;

      request.headers = {
        Authorization: `Bearer ${accessToken}`,
        IdToken: `${isTalkRequest ? '' : 'Bearer '}${idToken}`,
        'Tenant-Id': process.env.VUE_APP_TENANT_ID ?? '',
      };
      return request;
    }
  },
  async error => {
    hideLoading();

    // handle error
    const toast = await toastController.create({
      message: `Request error: ${JSON.stringify(error)}`,
      duration: 2000,
    });
    toast.present();
    // Do something with request error
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  async response => {
    hideLoading();
    return response;
  },
  async error => {
    hideLoading();
    await showErrorToast(error);
    // Do something with request error
    return Promise.reject(error);
  },
);

const showLoading = () => {
  if (loading) {
    loading.present();
  }
};

const increaseSpinnerStack = (url: string | undefined) => {
  spinnerStack.push(url ?? '');
};

const hideLoading = async () => {
  setTimeout(() => {
    spinnerStack.splice(0, 1);
    if (spinnerStack.length === 0) {
      loading?.dismiss();
    }
  }, 300);
};

const showErrorToast = async (error: any) => {
  const e: ApiError = { ...error.response.data };

  if (
    process.env.VUE_APP_GLOBAL_ERROR_IGNORE_PATH &&
    !e.path.startsWith(process.env.VUE_APP_GLOBAL_ERROR_IGNORE_PATH)
  ) {
    const message =
      e.errorDetails && e.errorDetails.message
        ? e.errorDetails.message
        : 'Something went wrong, please contact support.';
    const toast = await toastController.create({
      id: 'axiosErrorToast',
      message: `${message}`,
      icon: warningOutline,
      position: 'bottom',
      duration: 7000,
      buttons: [
        {
          text: 'Dismiss',
          role: 'cancel',
        },
      ],
      cssClass: 'error-toast',
      color: 'warning',
    });
    await toast.present();
  }
};

export { api, integratorApi };
