import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, Teleport as _Teleport, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ecbf1812"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "search-results"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 2 }
const _hoisted_5 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (
      $setup.checkPermission($setup.Permissions.FindJobs) ||
      $setup.checkAllPermissions($setup.Permissions.FindParticipations, $setup.Permissions.SeePersonalCandidateDetails)
    )
      ? (_openBlock(), _createBlock($setup["IonSearchbar"], {
          key: 0,
          debounce: "500",
          placeholder: `${_ctx.$t('search.search')}`,
          inputmode: "search",
          type: "search",
          enterkeyhint: "search",
          autocomplete: "off",
          autocorrect: "off",
          onIonChange: _cache[0] || (_cache[0] = ($event: any) => ($setup.handleSearch($event.target.value)))
        }, null, 8 /* PROPS */, ["placeholder"]))
      : _createCommentVNode("v-if", true),
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      ($setup.isOpen)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode($setup["IonCard"], null, {
              default: _withCtx(() => [
                _createVNode($setup["IonCardContent"], null, {
                  default: _withCtx(() => [
                    _createVNode($setup["IonCardHeader"], null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('search.projects')), 1 /* TEXT */)
                      ]),
                      _: 1 /* STABLE */
                    }),
                    (!$setup.isLoadingJobsInProgress)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.jobs, (result) => {
                            return (_openBlock(), _createBlock($setup["IonItem"], {
                              key: result.id,
                              "router-link": `/project/${result.id}`,
                              onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.handleClick()))
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(result.name), 1 /* TEXT */)
                              ]),
                              _: 2 /* DYNAMIC */
                            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["router-link"]))
                          }), 128 /* KEYED_FRAGMENT */)),
                          (!$setup.jobs?.length)
                            ? (_openBlock(), _createBlock($setup["IonItem"], {
                                key: 0,
                                disabled: ""
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t('search.projectNotFound')), 1 /* TEXT */)
                                ]),
                                _: 1 /* STABLE */
                              }))
                            : _createCommentVNode("v-if", true)
                        ]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                          _createVNode($setup["IonItem"], null, {
                            default: _withCtx(() => [
                              _createVNode($setup["IonSpinner"], {
                                name: "crescent",
                                color: "primary"
                              })
                            ]),
                            _: 1 /* STABLE */
                          })
                        ])),
                    _createVNode($setup["IonCardHeader"], { class: "ion-margin-top" }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('search.candidates')), 1 /* TEXT */)
                      ]),
                      _: 1 /* STABLE */
                    }),
                    (!$setup.isLoadingCandidatesInProgress)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.jobCandidatesList, (result) => {
                            return (_openBlock(), _createElementBlock("div", {
                              key: result.id
                            }, [
                              _createVNode($setup["IonItem"], {
                                "router-link": `/project/${result.jobOpeningId}/candidates/${result.id}`,
                                onClick: $setup.handleClick
                              }, {
                                default: _withCtx(() => [
                                  _createCommentVNode("<AvatarImg :documents=\"result.documents\" size=\"64\" />"),
                                  _createTextVNode(" " + _toDisplayString(result.contactInfo?.fullName) + " ", 1 /* TEXT */),
                                  _createVNode($setup["IonText"], {
                                    class: "ion-padding-start",
                                    color: "medium"
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(result.jobOpeningName), 1 /* TEXT */)
                                    ]),
                                    _: 2 /* DYNAMIC */
                                  }, 1024 /* DYNAMIC_SLOTS */)
                                ]),
                                _: 2 /* DYNAMIC */
                              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["router-link"])
                            ]))
                          }), 128 /* KEYED_FRAGMENT */)),
                          (!$setup.jobCandidatesList?.length)
                            ? (_openBlock(), _createBlock($setup["IonItem"], {
                                key: 0,
                                disabled: ""
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(_ctx.$t('search.candidateNotFound')), 1 /* TEXT */)
                                ]),
                                _: 1 /* STABLE */
                              }))
                            : _createCommentVNode("v-if", true)
                        ]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                          _createVNode($setup["IonItem"], null, {
                            default: _withCtx(() => [
                              _createVNode($setup["IonSpinner"], {
                                name: "crescent",
                                color: "primary"
                              })
                            ]),
                            _: 1 /* STABLE */
                          })
                        ]))
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            })
          ]))
        : _createCommentVNode("v-if", true)
    ]))
  ], 64 /* STABLE_FRAGMENT */))
}