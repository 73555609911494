export enum Permission {
  Viewer = 'emmyscore_viewer',
  ViewerPortal = 'emmyportal_viewer',
  ProjectCreate = 'emmyscore_project_create',
  ProjectLongtest = 'emmyscore_project_longtest',
  AdminConsoleAccess = 'adminconsole_access',
  EmmyPortalJob = 'emmyportal_job_',
  EmmyCoreAccess = 'emmycore_access',
  SovrenParsing = 'emmyscore_sovren_cv_parsing',
  RecruitmentServices = 'emmyscore_recruitment_services',
}

export enum PermissionAction {
  Reserve = 'reserve',
  Use = 'use',
  Cancel = 'cancel',
}
