import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(), _createBlock($setup["IonSegment"], {
      key: `talk_segments_${new Date().getMilliseconds()}`,
      scrollable: true,
      value: $setup.segment,
      onIonChange: _cache[0] || (_cache[0] = ($event: any) => ($setup.segmentChanged($event)))
    }, {
      default: _withCtx(() => [
        ($setup.hasNoPermission($setup.Permissions.ExternalReviewerRestrictions))
          ? (_openBlock(), _createBlock($setup["IonSegmentButton"], {
              key: 0,
              value: "talk_all",
              class: "tab center"
            }, {
              default: _withCtx(() => [
                _createVNode($setup["IonLabel"], null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('emmyTalk.messagesTab.title')), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true),
        _createVNode($setup["IonSegmentButton"], {
          value: "talk_candidates",
          class: "tab center"
        }, {
          default: _withCtx(() => [
            _createVNode($setup["IonLabel"], null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('emmyTalk.candidatesTab.title')), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }),
        _createVNode($setup["IonSegmentButton"], {
          value: "talk_projects",
          class: "tab center"
        }, {
          default: _withCtx(() => [
            _createVNode($setup["IonLabel"], null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('emmyTalk.projectsTab.title')), 1 /* TEXT */)
              ]),
              _: 1 /* STABLE */
            })
          ]),
          _: 1 /* STABLE */
        }),
        ($setup.hasRecruitmentServicesPermission)
          ? (_openBlock(), _createBlock($setup["IonSegmentButton"], {
              key: 1,
              value: "talk_services",
              class: "tab center"
            }, {
              default: _withCtx(() => [
                _createVNode($setup["IonLabel"], null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('emmyTalk.recruitmentServices.title')), 1 /* TEXT */)
                  ]),
                  _: 1 /* STABLE */
                })
              ]),
              _: 1 /* STABLE */
            }))
          : _createCommentVNode("v-if", true)
      ]),
      _: 1 /* STABLE */
    }, 8 /* PROPS */, ["value"])),
    _createVNode($setup["TalkSearch"], {
      class: "search",
      "is-overlay": $props.isOverlay
    }, null, 8 /* PROPS */, ["is-overlay"]),
    ($props.filteredTalks || $props.filteredCandidateTalks || $props.filteredProjectTalks)
      ? (_openBlock(), _createBlock($setup["MessagesOverviews"], {
          key: "message_overviews",
          "filtered-talks": $props.filteredTalks,
          "filtered-candidate-talks": $props.filteredCandidateTalks,
          "filtered-project-talks": $props.filteredProjectTalks,
          "filtered-services-talks": $props.filteredServicesTalks,
          "is-overlay": $props.isOverlay,
          "is-reached-candidate-end": $props.isReachedCandidateEnd,
          "is-reached-projects-end": $props.isReachedProjectsEnd,
          onGetMoreCandidatesTalkData: $setup.getMoreCandidateTalkData,
          onGetMoreProjectsTalkData: $setup.getMoreProjectsTalkData
        }, null, 8 /* PROPS */, ["filtered-talks", "filtered-candidate-talks", "filtered-project-talks", "filtered-services-talks", "is-overlay", "is-reached-candidate-end", "is-reached-projects-end"]))
      : _createCommentVNode("v-if", true)
  ], 64 /* STABLE_FRAGMENT */))
}