<template>
  <candidate-message-overview
    :candidate-data="candidateData"
    :is-overlay="isOverlay"
    :show-left-border="false"
    :show-project-name="false"
  />
</template>

<script setup lang="ts">
  import { JobCandidate } from '@/model/JobCandidate.model';
  import CandidateMessageOverview from '@/views/talk/components/messageOverviews/CandidateMessageOverview.vue';
  import { CandidateData, FunnelingStage } from '@/views/talk/model/CandidateData';
  import { computed } from 'vue';

  const props = defineProps<{
    candidate: JobCandidate;
    isOverlay: boolean;
  }>();

  const candidateData = computed(() => {
    return Object.assign(
      {
        id: '',
        jobOpeningId: '',
        jobOpeningEmmyProjectId: '',
        jobOpeningName: '',
        contactInfo: {
          salutation: '',
          formalSalutation: '',
          informalSalutation: '',
          firstName: '',
          lastName: '',
          fullName: '',
          emailAddress: [],
        },
        flowHistory: {
          byNewestFirst: [],
          current: '',
          id: '',
        },
        funnelingStage: FunnelingStage.LongList,
      },
      props.candidate,
    ) as CandidateData;
  });
</script>

<style scoped lang="scss">
  ion-card {
    box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    width: 100%;
    padding: 30px 20px 20px;
    margin: 7px 0 20px 10px;
    cursor: pointer;
  }

  ion-card-header {
    height: 45px;
    display: flex;
    align-items: center;
    padding: 0 0 30px;
    margin-top: 30px;
    margin-bottom: 20px;

    div {
      margin-left: 20px;
    }
  }

  ion-card-title {
    h4 {
      margin: 0;
      font-size: 1.3rem;
      font-weight: 600;
      color: #33c6d1;
    }

    p {
      font-size: 14px;
      margin-top: 4px;
      margin-bottom: 4px;
    }

    div {
      margin-left: 20px;
    }
  }

  ion-card-title {
    margin: 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: var(--font-title);
  }

  ion-card-subtitle {
    margin: 0;
    align-self: flex-start;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
  }

  ion-card-content {
    font-size: 1.2rem;
    padding: 0 0 20px;

    p {
      font-size: 1.2rem;
      line-height: 1.5rem;
    }
  }

  .active {
    --background: var(--background-step-finished);

    ion-card-title h4,
    ion-card-content {
      color: var(--font-primary);
    }
    ion-card-subtitle {
      color: var(--primary);
    }
  }

  ion-button {
    margin-left: auto;
    --background: transparent;
    --border-color: #25a8a9;
    --border-radius: 50px;
    --border-width: 2px;
    --border-style: solid;
    --color: #25a8a9;

    text-transform: none;
    font-size: 1.3rem;
    font-weight: 600;

    ion-icon {
      margin-right: 10px;
    }
  }

  .candidate-status > current-step {
    padding-left: 0px !important;
  }

  ion-item > .candidate-status__steps--current {
    padding-left: 10px !important;
    padding-right: 30px !important;
  }

  .candidate-status > ion-item {
    padding-left: 10px !important;
    padding-right: 30px !important;
  }

  .candidate-status__steps--current {
    --padding-left: 10px !important;
    --padding-right: 30px !important;
  }

  body
    > div.overlay
    > ion-card
    > div
    > ion-list:nth-child(3)
    > ion-item:nth-child(1)
    > ion-card
    > ion-card-content
    > ion-card:nth-child(1)
    > ion-chip
    > ion-item.candidate-status__steps--current.item.md.in-list.ion-focusable.hydrated {
    --padding-left: 10px !important;
    --padding-right: 30px !important;
    padding-left: 10px !important;
    padding-right: 30px !important;
  }

  .wrap-text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 200px;
  }

  .display-flex {
    display: flex;
  }

  .justify-content-space-between {
    justify-content: space-between;
  }

  .chat-button {
    top: 5px;
    left: 35px;
  }
</style>
