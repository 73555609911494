import { api } from '@/config/AxiosConfig';
import { StrictUseAxiosReturn, useAxios } from '@vueuse/integrations/useAxios';
import { ParticipationLabel, Tenant } from '@/model/Tenant.model';
import { computed } from 'vue';
import { JobLabel } from '@/model/Job.model';
import { TenantNotification } from '@/model/TenantNotification.model';
import { UpdateEmmyNotificationDto } from '@/composables/dto/TenantEmmyNotificationDto';

// GLOBAL STATE - tenant configuration data
const { data, execute }: StrictUseAxiosReturn<Tenant> & PromiseLike<StrictUseAxiosReturn<Tenant>> = useAxios<Tenant>(
  '/v1/tenant/current',
  api,
  { immediate: false },
);

export function useTenantDetailsGlobalState() {
  const loadTenantData = () => {
    execute();
  };

  /**
   * Load tenant data from backend only if not already loaded.
   */
  const loadTenantDataIfNotLoaded = async () => {
    if (!data.value) {
      await execute();
    }
  };

  const createTenantCandidateLabel = async (label: ParticipationLabel) => {
    const { error } = await useAxios<void>(
      `/v1/tenant/current/candidate-labels`,
      {
        method: 'POST',
        data: {
          name: label.name,
          color: label.color,
          visibility: label.visibility,
        },
      },
      api,
    );
    return error;
  };

  const createTenantJobLabel = async (label: JobLabel) => {
    const { error } = await useAxios<void>(
      `/v1/tenant/current/job-labels`,
      {
        method: 'POST',
        data: {
          name: label.name,
          color: label.color,
        },
      },
      api,
    );
    return error;
  };

  const updateTenantCandidateLabel = async (label: ParticipationLabel) => {
    const { error } = await useAxios<void>(
      `/v1/tenant/current/candidate-labels/${label.id}`,
      {
        method: 'PUT',
        data: {
          name: label.name,
          color: label.color,
          visibility: label.visibility,
        },
      },
      api,
    );
    return error;
  };

  const updateTenantJobLabel = async (label: JobLabel) => {
    const { error } = await useAxios<void>(
      `/v1/tenant/current/job-labels/${label.id}`,
      {
        method: 'PUT',
        data: {
          name: label.name,
          color: label.color,
        },
      },
      api,
    );
    return error;
  };

  const deleteTenantCandidateLabel = async (labelId: string) => {
    const { error } = await useAxios<void>(
      `/v1/tenant/current/candidate-labels/${labelId}`,
      {
        method: 'DELETE',
      },
      api,
    );
    return error;
  };

  const deleteTenantJobLabel = async (labelId: string) => {
    const { error } = await useAxios<void>(
      `/v1/tenant/current/job-labels/${labelId}`,
      {
        method: 'DELETE',
      },
      api,
    );
    return error;
  };

  const changeNotificationState = async (notification: TenantNotification) => {
    const dto = UpdateEmmyNotificationDto.createFromEmmyNotification(notification);
    const { error } = await useAxios<void>(
      `/v1/tenant/current/notifications/${notification.id}`,
      {
        method: 'PUT',
        data: dto,
      },
      api,
    );
    return error;
  };

  const isEthTenant = () => {
    /*        if (process.env['NODE_ENV'] === 'development') {
                    return true;
                }*/
    return process.env['VUE_APP_TENANT_DOMAIN'] ? process.env['VUE_APP_TENANT_DOMAIN'].indexOf('ethz') >= 0 : false;
  };

  const candidateLabels = computed(() => {
    return data.value?.candidateLabels || [];
  });

  const projectLabels = computed(() => {
    return data.value?.jobLabels || [];
  });

  const tenantTraits = computed(() => {
    return data.value?.traits || [];
  });

  const notificationSettings = computed(() => {
    return data.value?.notificationSettings;
  });

  const jobBoardSettings = computed(() => {
    return data.value?.jobBoardSettings;
  });

  return {
    loadTenantData,
    loadTenantDataIfNotLoaded,
    createTenantJobLabel,
    createTenantCandidateLabel,
    updateTenantJobLabel,
    updateTenantCandidateLabel,
    deleteTenantJobLabel,
    deleteTenantCandidateLabel,
    changeNotificationState,
    isEthTenant,
    data,
    candidateLabels,
    projectLabels,
    tenantTraits,
    notificationSettings,
    jobBoardSettings,
  };
}
