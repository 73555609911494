/**
 * This values will be replaced by CI pipeline
 */
export const GIT_COMMIT_ID = '694b0462bb36e9e172a9138e56775e26ec231232';
export const BUILD_DATE = 'Tue Apr  2 00:28:55 UTC 2024';
export const VERSION = '1.4.0';

const CURRENT_ENV = '516745382884';
export const ENVIRONMENT = (): string => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return CURRENT_ENV === '510552048455' ? 'TEST' : 'PROD';
};
