export enum FunnelingStage {
  Unprocessed = 'unprocessed',
  LongList = 'longList',
  ShortList = 'shortList',
  Rejected = 'rejected',
}

export interface CandidateFlowData {
  stage: FunnelingStage;
  inStateSince: Date;
  id: string;
}

export interface CandidateData {
  id: string;
  jobOpeningId: string;
  jobOpeningEmmyProjectId: string;
  jobOpeningName: string;
  contactInfo: {
    salutation: string;
    formalSalutation: string;
    informalSalutation: string;
    firstName: string;
    lastName: string;
    fullName: string;
    emailAddress: string[];
  };
  flowHistory: {
    byNewestFirst: CandidateFlowData[];
    current: string;
    id: string;
  };
  funnelingStage: FunnelingStage;
  referenceColor: string;
}
