import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-29a25cb8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "searchbar" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode($setup["IonSearchbar"], {
      modelValue: $setup.talksSearchCriteria,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.talksSearchCriteria) = $event)),
      placeholder: _ctx.$t('search.search'),
      inputmode: "search",
      type: "search",
      enterkeyhint: "search",
      autocomplete: "off",
      autocorrect: "off"
    }, null, 8 /* PROPS */, ["modelValue", "placeholder"])
  ]))
}