<template>
  <ion-segment
    :key="`talk_segments_${new Date().getMilliseconds()}`"
    :scrollable="true"
    :value="segment"
    @ionChange="segmentChanged($event)"
  >
    <ion-segment-button
      v-if="hasNoPermission(Permissions.ExternalReviewerRestrictions)"
      value="talk_all"
      class="tab center"
    >
      <ion-label>{{ $t('emmyTalk.messagesTab.title') }}</ion-label>
    </ion-segment-button>
    <ion-segment-button value="talk_candidates" class="tab center">
      <ion-label>{{ $t('emmyTalk.candidatesTab.title') }}</ion-label>
    </ion-segment-button>
    <ion-segment-button value="talk_projects" class="tab center">
      <ion-label>{{ $t('emmyTalk.projectsTab.title') }}</ion-label>
    </ion-segment-button>
    <ion-segment-button v-if="hasRecruitmentServicesPermission" value="talk_services" class="tab center">
      <ion-label>{{ $t('emmyTalk.recruitmentServices.title') }}</ion-label>
    </ion-segment-button>
  </ion-segment>
  <talk-search class="search" :is-overlay="isOverlay" />
  <messages-overviews
    v-if="filteredTalks || filteredCandidateTalks || filteredProjectTalks"
    key="message_overviews"
    :filtered-talks="filteredTalks"
    :filtered-candidate-talks="filteredCandidateTalks"
    :filtered-project-talks="filteredProjectTalks"
    :filtered-services-talks="filteredServicesTalks"
    :is-overlay="isOverlay"
    :is-reached-candidate-end="isReachedCandidateEnd"
    :is-reached-projects-end="isReachedProjectsEnd"
    @get-more-candidates-talk-data="getMoreCandidateTalkData"
    @get-more-projects-talk-data="getMoreProjectsTalkData"
  />
</template>

<script setup lang="ts">
  import { IonLabel, IonSegment, IonSegmentButton } from '@ionic/vue';

  import TalkSearch from './TalkSearch.vue';
  import MessagesOverviews from './MessageOverviews.vue';
  import { Talk } from '@/views/talk/model/Talk.model';
  import { CandidateData } from '@/views/talk/model/CandidateData';
  import { ProjectData } from '@/views/talk/model/ProjectData';
  import { RecruitmentServicesData } from '@/views/talk/model/RecruitmentServicesData';
  import { useWebComponentsGlobalState } from '@/composables/useWebComponentsGlobalState';
  import { onBeforeMount, ref, Ref } from 'vue';
  import { useAuthGlobalState } from '@/composables/useAuthGlobal';
  import { Permissions } from '@/model/permission.model';

  const { checkPermission, hasNoPermission } = useAuthGlobalState();

  const segment: Ref<string> = ref('talk_all');
  const { talksSegmentSelected } = useWebComponentsGlobalState();

  const hasRecruitmentServicesPermission: Ref<boolean> = ref(false);

  onBeforeMount(async () => {
    talksSegmentSelected.value = hasNoPermission(Permissions.ExternalReviewerRestrictions)
      ? 'talk_all'
      : 'talk_candidates';
    segment.value = talksSegmentSelected.value;
    await checkHasRecruitmentServicesPermission();
  });

  defineProps<{
    isOverlay: boolean;
    filteredTalks?: Talk[] | undefined;
    filteredCandidateTalks?: CandidateData[] | undefined;
    filteredProjectTalks?: ProjectData[] | undefined;
    filteredServicesTalks?: RecruitmentServicesData[] | undefined;
    isReachedCandidateEnd: boolean;
    isReachedProjectsEnd: boolean;
  }>();

  const segmentChanged = (event: any) => {
    talksSegmentSelected.value = event.detail.value;
    segment.value = event.detail.value;
  };

  const emit = defineEmits<{
    (e: 'getMoreCandidatesTalkData'): void;
    (e: 'getMoreProjectsTalkData'): void;
  }>();

  const getMoreCandidateTalkData = () => {
    emit('getMoreCandidatesTalkData');
  };

  const getMoreProjectsTalkData = () => {
    emit('getMoreProjectsTalkData');
  };

  const checkHasRecruitmentServicesPermission = async () => {
    hasRecruitmentServicesPermission.value = checkPermission(Permissions.ViewJobDashboard);
  };
</script>

<style scoped lang="scss">
  ion-segment {
    margin-left: 6px;
    margin-right: 6px;
  }

  ion-label {
    font-size: 1.4rem;
    opacity: 0.6;
    font-weight: 600;
    text-transform: none;
    color: #f5f6fb;
  }

  .segment-button-checked {
    &::part(indicator-background) {
      width: 80%;
      margin: 0 auto;
      --indicator-height: 4px;
      background: var(--background-selected);
    }

    ion-label {
      color: var(--font-white);
      opacity: 1;
    }
  }

  .search {
    margin-top: 4px;
  }

  .placeholder {
    min-height: 72px;
  }
</style>
