import { firebaseConfig, firebaseMessaging } from '@/config/FirebaseConfig';
import { i18n } from '@/config/VueI18n';
import { PushNotificationManager } from '@emmysoft-gmbh/push-notification-service';
import { getToken } from 'firebase/messaging';
import { Ref, computed, ref } from 'vue';
import { useAuth } from './useAuth';
import { Permission } from '@/model/EmmyScorePermissions.model';
import { Auth } from 'aws-amplify';

/**
 * This is a shared manager which gets used by all instances of the `usePushNotifications` composable.
 */
let sharedPushNotificationManager: PushNotificationManager | undefined = undefined;
const sharedDeviceToken: Ref<string | undefined> = ref(undefined);

/**
 * Composable for interacting with the push notification service.
 * @param pushNotificationManager The push notification manager instance to use. This class is coming from the
 *  `@emmysoft-gmbh/push-notification-service` package.
 * Most important functions are:
 * - `registerDevice()`: This will request the firebase device token and will send it to the backend.
 * - `unregisterDevice()`: Removes the registered device from the server.
 */
export function usePushNotifications() {
  /**
   * Returns weather the device is registered with the backend or not.
   */
  const isRegistered = computed(function computeIsRegistered() {
    return sharedDeviceToken.value !== undefined && sharedPushNotificationManager !== undefined;
  });

  /**
   * This will request the firebase device token and will send it to the backend.
   * This should be called whenever a new user logs in or he reloads the page and is already logged in.
   * If his device id is already known to the server the timestamp of his last activity will be updated, this is
   * to later be able to delete inactive devices.
   * @returns A promise that resolves to the newly created device id.
   */
  async function registerDevice() {
    // console.debug('Register Device')
    const auth = useAuth();

    let isAuthenticated = false;
    try {
      isAuthenticated = (await Auth.currentAuthenticatedUser()) ? true : false;
      if (isAuthenticated) {
        isAuthenticated = await auth.checkUserHavePermission(Permission.Viewer);
      }
    } catch {
      isAuthenticated = false;
    }

    if (!isAuthenticated) {
      // console.warn(
      //   'User is not authenticated or does not have permission to register device'
      // )
      return;
    }

    const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();

    sharedPushNotificationManager = new PushNotificationManager({
      baseUrl: process.env.VUE_APP_PUSH_NOTIFICATION_BASE_URL as string,
      tenantId: process.env.VUE_APP_TENANT_ID as string,
      idToken,
    });

    // Request permission for notifications
    const permissionGranted = await _requestPermission();

    if (!permissionGranted) {
      console.warn('Permission for notifications not granted');
      return;
    }

    const serviceWorker = await navigator.serviceWorker.register('/score/firebase-messaging-sw.js', {
      scope: '/score/firebase-cloud-messaging-push-scope',
    });

    const token = await getToken(firebaseMessaging, {
      vapidKey: firebaseConfig.vapidKey,
      serviceWorkerRegistration: serviceWorker,
    });

    const newDevice = await sharedPushNotificationManager.createDevice({
      _id: token,
      language: i18n.global.locale.value,
    });

    sharedDeviceToken.value = token;
    // console.debug('Device registered successfully')
    // console.debug('Device Token:', sharedDeviceToken.value)
    return newDevice;
  }

  /**
   * Removes the registered device from the server. This should be done when the user loggs out, so that his device
   * no longer receives any notifications for the user he was logged in with.
   * @returns
   */
  async function unregisterDevice() {
    // console.debug('Unregister Device')
    const token = sharedDeviceToken.value;

    // console.debug('Device Token:', token)

    if (!token) {
      // console.warn('No device token available, cannot unregister device')
      return;
    }

    if (!sharedPushNotificationManager) {
      // console.warn(
      //   'No push notification manager available, cannot unregister device'
      // )
      return;
    }

    await sharedPushNotificationManager.deleteDevice(token);
    sharedDeviceToken.value = undefined;
    sharedPushNotificationManager = undefined;
  }

  /**
   * Changes the language of the device on the server.
   * @param language The iso code of the language to change to. E.g. 'de' or 'en'.
   * @returns A promise that resolves to the updated device.
   */
  async function changeLanguage(language: string) {
    // console.debug('Change Language for Device')
    if (!sharedDeviceToken.value) {
      // console.warn('No device token available, cannot change language')
      return;
    }

    if (!sharedPushNotificationManager) {
      // console.warn(
      //   'No push notification manager available, cannot change language'
      // )
      return;
    }

    return sharedPushNotificationManager.createDevice({
      _id: sharedDeviceToken.value,
      language,
    });
  }

  /**
   * Private helper function to request permission from the browser to send notifications.
   * @returns A promise that resolves to true if the permission was granted, false otherwise.
   */
  async function _requestPermission() {
    const permission = await Notification.requestPermission();

    if (permission === 'granted') {
      return true;
    }

    return false;
  }

  return {
    registerDevice,
    unregisterDevice,
    changeLanguage,
    isRegistered,
  };
}
