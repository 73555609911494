import { StrictUseAxiosReturn, useAxios } from '@vueuse/integrations/useAxios';
import { api } from '@/config/AxiosConfig';
import { Job } from '@/model/Job.model';
import { ProjectUpdate } from '@/views/project/model/ProjectUpdate.model';

//GLOBAL STATE - used by breadcrumbs
const { data, isLoading, execute }: StrictUseAxiosReturn<Job> & PromiseLike<StrictUseAxiosReturn<Job>> = useAxios<Job>(
  `/v1/projects`,
  api,
  { immediate: false },
);

export function useProjectDetailsGlobalState() {
  function loadProjectDetails(id: string) {
    execute(getUrl(id));
  }

  const updateProjectDetails = async (project: ProjectUpdate, id: string) => {
    const { error } = await useAxios<void>(`/v1/projects/${id}`, { method: 'PUT', data: project }, api);
    return error;
  };

  function getUrl(projectId: string) {
    return `/v1/projects/${projectId}`;
  }

  return {
    data,
    isLoading,
    loadProjectDetails,
    updateProjectDetails,
  };
}
