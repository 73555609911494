import { createI18n } from 'vue-i18n';

import en from '@/locales/en.json';
import de from '@/locales/de.json';
import { watch } from 'vue';
import { usePushNotifications } from '@/composables/usePushNotifications';

const messages = {
  en,
  de,
};

export const i18n = createI18n({
  legacy: false,
  locale: 'de',
  fallbackLocale: 'en',
  warnHtmlMessage: false, // disable warning HTML in message
  missingWarn: false, // disable warning missing message
  fallbackWarn: false, // disable warning fallback message
  messages,
});

watch(i18n.global.locale, newLang => {
  usePushNotifications().changeLanguage(newLang);
});
