import { OpenTalkByReference } from '@/composables/useWebComponentsGlobalState';

const projectColor: string[] = [
  '#02A79F',
  '#45D35C',
  '#74DED9',
  '#337CB7',
  '#E6A01F',
  '#FCEB46',
  '#02A79F',
  '#45D35C',
  '#74DED9',
  '#337CB7',
  '#E6A01F',
  '#FCEB46',
  '#02A79F',
];

export enum TalkIdentifier {
  portal = 'portal#',
  score = 'score#',
}

export function useEmmyTalk() {
  const getObjectColor = (index: number): string => {
    return projectColor[index % projectColor.length];
  };

  const getTalkReferenceObject = (talk: any, isOverlay: any) => {
    return {
      talkId: talk._id,
      referenceColor: talk.referenceColor,
      referenceType: talk.reference?.talkType,
      title: talk.title,
      isOverlay: isOverlay,
    };
  };

  const getProjectReference = (refObj?: OpenTalkByReference): string => {
    if (refObj) {
      if (refObj.emmyProjectId && refObj.emmyProjectId.length > 0) {
        return `${TalkIdentifier.portal}${refObj.emmyProjectId}`;
      } else if (refObj.projectId && refObj.projectId.length > 0) {
        return `${TalkIdentifier.score}${refObj.projectId}`;
      }
    }

    return '';
  };

  const getApplicationsReference = (refObj?: OpenTalkByReference): string => {
    if (refObj) {
      if (refObj.applicationId && refObj.applicationId.length > 0) {
        return refObj.applicationId;
      }
    }

    return '';
  };

  const getTalkDOMIdentifier = (refObj?: OpenTalkByReference): string => {
    if (refObj) {
      if (refObj.emmyProjectId && refObj.emmyProjectId.length > 0) {
        return `${refObj.emmyProjectId}${refObj.applicationId ?? ''}-${refObj.talkType}`;
      } else if (refObj.projectId && refObj.projectId.length > 0) {
        return `${refObj.projectId}${refObj.applicationId ?? ''}-${refObj.talkType}`;
      } else if (refObj.applicationId && refObj.applicationId.length > 0) {
        return `${refObj.applicationId}-${refObj.talkType}`;
      }
    }

    return '';
  };

  return {
    getObjectColor,
    getTalkReferenceObject,
    getProjectReference,
    getApplicationsReference,
    getTalkDOMIdentifier,
  };
}
