import { EmmyCandidate } from './EmmyCandidate.model';
import { JobDescription } from './JobDescription.model';
import { Qualifications } from './Qualifications.model';
import { MultilingualValue } from './MultilingualValue.model';
import { PersonalityProfileResult } from './JobCandidate.model';
import { ResponsibleRecruiter } from '@/views/project/model/ProjectUpdate.model';
import { SovrenJobData } from '@/model/SovrenJobData';
import { JobOpeningParticipationStatistics } from '@/model/JobOpeningParticipationStatistics.model';
import { Group } from '@/model/Group.model';
import { AppliedTrait } from '@/views/project/model/Trait.model';

export interface Job {
  id: string;
  name: string;
  characterization: JobCharacterization;
  description: JobDescription;
  labels?: JobLabel[];
  role: string;
  function: string;
  state: JobState;
  qualifications: Qualifications;
  screening?: Assessment;
  assessing?: Assessment;
  responsibleRecruiter?: ResponsibleRecruiter;
  settings?: JobOpeningSettings;
  emmyProjectId?: string;
  emmyCandidates?: EmmyCandidate[];
  hasInvitedCandidates: boolean;
  hasParticipations: boolean;
  hasParticipationsInAssessingRound: boolean;
  allParticipationsProcessed: boolean;
  candidateFrontendUrl?: string;
  personalityProfile?: PersonalityProfileResult;
  profileType?: string;
  vonqCampaignIds?: string[];
  accountId: string;
  sovren?: SovrenJobData;
  jobOpeningParticipationStatistics?: JobOpeningParticipationStatistics;
  hiringManagers?: HiringManagerData[];
  groupIds?: number[];
  appliedTraits?: AppliedTrait[];
  softfactorsDataId: string;
}

export interface JobOpeningSettings {
  availableLanguages: Language[];
  theme: string;
  presentation: PresentationSettings;
  dataGathering: DataGatheringSettings;
  automatedEmails: AutomatedEmailSettings;
  emailTemplates: UserEmailTemplates;
  jobBoard: JobBoardConfigurationSettings;
  externalLandingPageUrl: string;
}

export interface PresentationSettings {
  jobWelcomeText: MultilingualValue<string>;
  personalizedWelcomeText: MultilingualValue<string>;
  thankYouText: MultilingualValue<string>;
  assessingWelcomeText: MultilingualValue<string>;
  assessingThankYouText: MultilingualValue<string>;
  provideFeedbackReport: boolean;
}

export interface DataGatheringSettings {
  phone?: string;
  birthday?: GatheringSetting;
  location?: GatheringSetting;
  state?: GatheringSetting;
  country?: GatheringSetting;
  additionalCandidateStatement?: GatheringSetting;
  attachmentUpload?: GatheringSetting;
  academicTitle?: GatheringSetting;
  nationalities?: GatheringSetting;
  workPermit?: GatheringSetting;
  coverLetterUpload?: GatheringSetting;
  gradesUpload?: GatheringSetting;
}

export interface EmailTemplate {
  subject: MultilingualValue<string>;
  body: MultilingualValue<string>;
  attachmentFilePatterns: string[];
}

export interface AutomatedEmailSettings {
  senderAddress: string;
  welcomeMail: EmailTemplate;
  confirmationMail: EmailTemplate;
  reminderMail: EmailTemplate;
  sendReminderMail: boolean;
}

export interface UserEmailTemplates {
  rejectionMail: EmailTemplate;
  assessingInvitationMail: EmailTemplate;
  messageToCandidateMail: EmailTemplate;
}

export interface JobBoardConfigurationSettings {
  includeInJobBoard: boolean;
  leadTitle: MultilingualValue<string>;
  leadText: MultilingualValue<string>;
}

export enum GatheringSetting {
  Ignored = 'ignored',
  Optional = 'optional',
  Required = 'required',
}

export enum Language {
  En = 'en',
  De = 'de',
  Fr = 'fr',
  It = 'it',
  Nl = 'nl',
  Pl = 'pl',
  Es = 'es',
}

export interface Assessment {
  instrumentSet: InstrumentSet;
  measuresHardFactors: boolean;
  measuresSoftFactors: boolean;
  instrumentConfigurations: InstrumentConfiguration[];
}

export interface InstrumentConfiguration {
  instrumentKind?: InstrumentKind;
  requirements: JobRequirement[];
}

export enum InstrumentKind {
  JobAssessment = 'jobAssessment',
  HowISeeMyself = 'howISeeMyself',
  MyPreferences = 'myPreferences',
  CvRequirements = 'cvRequirements',
  ReadingColleagues = 'readingColleagues',
  NumericalComputation = 'numericalComputation',
  VerbalReasoning = 'verbalReasoning',
  CommunicationBehavior = 'communicationBehavior',
  LeadershipBehavior = 'leadershipBehavior',
  MotivationalDrivers = 'motivationalDrivers',
  CopingWithStress = 'copingWithStress',
  ConflictStyle = 'conflictStyle',
  ConflictHandling = 'conflictHandling',
  FactFinding = 'factFinding',
  AnalyzedVideo = 'analyzedVideo',
}

export interface JobRequirement {
  requirementId: string;
  questionText: MultilingualValue<string>;
  category: RequirementCategory;
  requirement: Requirement;
  weight: number;
  isKiller: boolean;
}

export const TemporaryNewRequirementId = 'temporary_requirement_id';

export enum RequirementCategory {
  Education = 'education',
  Experience = 'experience',
  Language = 'language',
  Salary = 'salary',
  Skill = 'skill',
  Location = 'location',
  TravelWillingness = 'travelWillingness',
  Other = 'other',
}

export enum RequirementAnswerMethod {
  YesOrNo = 'yesOrNo',
  NumberSlider = 'numberSlider',
  Language = 'language',
  NumberInput = 'numberInput',
  Percentage = 'percentage',
  Location = 'location',
  FreeText = 'freeText',
  Skill = 'skill',
  PseudoQuestionTitle = 'pseudoQuestionTitle',
  PseudoQuestionText = 'pseudoQuestionText',
}

export interface Requirement {
  answerMethod: RequirementAnswerMethod;
  requiredChoice?: string;
  maximumRequirement?: string;
  minimumRequirement?: string;
  sliderMaximumValue?: number;
  sliderMinimumValue?: number;
  answerKind?: string;
  isOptional?: boolean;
}

export enum InstrumentSet {
  Empty = 'empty',
  ScreeningWithHardFacts = 'screeningWithHardFacts',
  PersonalityAtWork = 'personalityAtWork',
  Screening = 'screening',
  HardFactsOnly = 'hardFactsOnly',
  Assessment1 = 'assessment1',
  Assessment2 = 'assessment2',
  Assessment1Leadership = 'assessment1Leadership',
  Assessment2Leadership = 'assessment2Leadership',
  CombinedWithHardFacts = 'combinedWithHardFacts',
  CombinedWithoutHardFacts = 'combinedWithoutHardFacts',
  DevelopmentAssessmentWithHardFacts = 'developmentAssessmentWithHardFacts',
  DevelopmentAssessmentWithoutHardFacts = 'developmentAssessmentWithoutHardFacts',
  PotentialDevelopmentAssessment = 'potentialDevelopmentAssessment',
  PotentialDevelopmentAssessmentWithAnalyzedVideo = 'potentialDevelopmentAssessmentWithAnalyzedVideo',
  CombinedWithHardFactsLeadership = 'combinedWithHardFactsLeadership',
  CombinedWithoutHardFactsLeadership = 'combinedWithoutHardFactsLeadership',
  Development = 'development',
  AllInstruments = 'allInstruments',
}

export interface JobLabel {
  id: string;
  name: string;
  color: string;
}

export enum JobState {
  Draft = 'draft',
  ReleaseCandidate = 'releaseCandidate',
  Published = 'published',
  Closed = 'closed',
  Terminated = 'terminated',
  Archived = 'archived',
}

export enum JobRole {
  Unspecified = 'unspecified',
  IndividualContributor = 'individualContributor',
  JuniorExpert = 'juniorExpert',
  SeniorExpert = 'seniorExpert',
  TeamLeader = 'teamLeader',
  ProjectLeader = 'projectLeader',
  ManagerOfManagers = 'managerOfManagers',
  Executive = 'executive',
}

export enum JobFunction {
  Unspecified = 'unspecified',
  FinanceControlling = 'financeControlling',
  FinanceTechnical = 'financeTechnical',
  MarketingSales = 'marketingSales',
  ProductionEngineeringOperations = 'productionEngineeringOperations',
  SupplyChain = 'supplyChain',
  ResearchDevelopment = 'researchDevelopment',
  CustomerService = 'customerService',
  InformationTechnology = 'informationTechnology',
  HumanResources = 'humanResources',
  Legal = 'legal',
  ProjectManagement = 'projectManagement',
  ExecutiveOffice = 'executiveOffice',
}

export interface JobCharacterization {
  internalClassification: string;
  pensum: Pensum;
  contractType?: ContractKind;
  placeOfWork: JobAddress;
}

export interface Pensum {
  minimumValue?: number;
  maximumValue?: number;
}

export enum ContractKind {
  Unlimited = 'unlimited',
  Limited = 'limited',
}

export interface JobAddress {
  street: string;
  zipCode: string;
  city: string;
  state: string;
  country: string;
}

export interface RequirementReorder {
  from: number;
  to: number;
}

export interface JobLists {
  default: Job[];
  reviewing: Job[];
  hm: Job[];
  owner: Job[];
  groups: Group[];
}

export interface HiringManagerData {
  email: string;
  fullName: string;
}
