<template>
  <teleport v-if="!isTalkRoute" to="body">
    <div
      class="overlay mobile"
      :class="{
        emmyTalkOpen: talkOverlayOpen,
        emmyTalkFullscreen: talkOverlayFullScreen,
      }"
      :style="{
        'padding-top': talkOverlayOpen && isMobile && talkOverlayFullScreen ? '64px' : '0px',
      }"
    >
      <div v-for="overlayTalk in overlayTalks" :key="useEmmyTalk().getTalkDOMIdentifier(overlayTalk)">
        <talk-message-overlay :open-by-reference="overlayTalk" />
      </div>
      <div v-if="!isSidebarOpen" key="sidebarSeparator" style="padding-right: 65px"></div>
      <talk-sidebar
        :filtered-talks="filteredTalks"
        :filtered-candidate-talks="filteredCandidateTalks"
        :filtered-project-talks="filteredProjectTalks"
        :filtered-services-talks="filteredServicesTalks"
        :is-overlay="true"
        :is-reached-candidate-end="isReachedCandidateEnd"
        :is-reached-projects-end="isReachedProjectsEnd"
        @sidebar-open="sidebarOpen"
        @sidebar-closed="sidebarClosed"
        @get-more-candidates-talk-data="getMoreCandidateTalkData"
        @get-more-projects-talk-data="getMoreProjectTalkData"
      />
    </div>
  </teleport>
</template>

<script setup lang="ts">
  import TalkSidebar from '@/views/talk/components/TalkSidebar.vue';
  import TalkMessageOverlay from '@/views/talk/components/TalkMessageOverlay.vue';

  import { isPlatform } from '@ionic/vue';
  import { computed, onMounted, ref, Ref, watch } from 'vue';
  import { useRoute } from 'vue-router';
  import { useWebComponentsGlobalState } from '@/composables/useWebComponentsGlobalState';
  import { Talk } from '@/views/talk/model/Talk.model';
  import { CandidateData } from '@/views/talk/model/CandidateData';
  import { ProjectData } from '@/views/talk/model/ProjectData';
  import { useEmmyTalk } from '@/views/talk/composables/useEmmyTalk';
  import { RecruitmentServicesData } from '@/views/talk/model/RecruitmentServicesData';
  import { useAuthGlobalState } from '@/composables/useAuthGlobal';
  import { Permissions } from '@/model/permission.model';

  const { checkPermission } = useAuthGlobalState();

  const {
    talkOverlayOpen,
    talkOverlayFullScreen,
    talksData,
    candidatesData,
    projectsTalksData,
    talksSearchCriteria,
    overlayTalks,
    getCandidatesData,
    getProjectsTalks,
    getTalks,
    talksSegmentSelected,
    isLoadingFirst,
    isLoading,
  } = useWebComponentsGlobalState();
  const route = useRoute();
  const { getObjectColor } = useEmmyTalk();

  const hasRecruitmentServicesPermission: Ref<boolean> = ref(false);
  const isSidebarOpen: Ref<boolean> = ref(false);

  const isReachedCandidateEnd = ref<boolean>(false);
  const isReachedProjectsEnd = ref<boolean>(false);

  const candidateTalkOffset = ref<number>(0);
  const projectTalkOffset = ref<number>(0);

  const candidatesDataRef = ref<CandidateData[] | undefined>([]);
  const projectTalksDataRef = ref<ProjectData[] | undefined>([]);

  const getMoreCandidateTalkData = async () => {
    candidateTalkOffset.value += 20;
    isLoading.value = true;
    await getCandidatesData(candidateTalkOffset.value, talksSearchCriteria.value || '');
    isLoading.value = false;
    if (candidatesData.value && candidatesData.value.length === 20) {
      candidatesDataRef.value?.push(...candidatesData.value);
    } else {
      candidatesData.value && candidatesData.value.length > 0 && candidatesDataRef.value?.push(...candidatesData.value);
      isReachedCandidateEnd.value = true;
    }
  };

  const getMoreProjectTalkData = async () => {
    projectTalkOffset.value += 20;
    isLoading.value = true;
    await getProjectsTalks(projectTalkOffset.value, talksSearchCriteria.value || '');
    isLoading.value = false;
    if (projectsTalksData.value && projectsTalksData.value.length === 20) {
      projectTalksDataRef.value?.push(...projectsTalksData.value);
    } else {
      projectsTalksData.value &&
        projectsTalksData.value.length > 0 &&
        projectTalksDataRef.value?.push(...projectsTalksData.value);
      isReachedProjectsEnd.value = true;
    }
  };

  const checkHasRecruitmentServicesPermission = async () => {
    hasRecruitmentServicesPermission.value = checkPermission(Permissions.ViewJobDashboard);
  };

  onMounted(async () => {
    await checkHasRecruitmentServicesPermission();
  });

  const sidebarOpen = async () => {
    await loadSegmentData(talksSegmentSelected.value);
  };

  const sidebarClosed = () => {
    isSidebarOpen.value = false;
  };

  const filteredTalks = computed((): Talk[] => {
    let filteredTalks: Talk[] = [];

    if (talksData.value) {
      if (talksSearchCriteria.value && talksSearchCriteria.value.length > 0) {
        filteredTalks = talksData.value.filter(talk => {
          return talk.title
            .toLowerCase()
            .includes(talksSearchCriteria.value ? talksSearchCriteria.value.toLowerCase() : '');
        });
      } else {
        filteredTalks = talksData.value;
      }

      let index = 0;
      for (const talk of filteredTalks) {
        talk.referenceColor = getObjectColor(index);
        index += 1;
      }
    }

    return filteredTalks;
  });

  const filteredCandidateTalks = computed((): CandidateData[] => {
    let filteredCandidateTalks: CandidateData[] = [];

    if (candidatesDataRef.value) {
      filteredCandidateTalks = candidatesDataRef.value;
    }

    let index = 0;
    for (const talk of filteredCandidateTalks) {
      talk.referenceColor = getObjectColor(index);
      index += 1;
    }

    return filteredCandidateTalks;
  });

  const filteredProjectTalks = computed((): ProjectData[] => {
    let filteredProjectTalks: ProjectData[] = [];

    if (projectTalksDataRef.value) {
      filteredProjectTalks = projectTalksDataRef.value;
    }

    let index = 0;
    for (const talk of filteredProjectTalks) {
      talk.referenceColor = getObjectColor(index);
      index += 1;
    }

    return filteredProjectTalks;
  });

  const filteredServicesTalks = computed((): RecruitmentServicesData[] => {
    let filteredServicesTalks: RecruitmentServicesData[] = [];

    if (hasRecruitmentServicesPermission.value && projectTalksDataRef.value) {
      filteredServicesTalks = projectTalksDataRef.value;

      let index = 0;
      for (const talk of filteredServicesTalks) {
        talk.referenceColor = getObjectColor(index);
        index += 1;
      }
    }

    return filteredServicesTalks;
  });

  watch(talksSearchCriteria, async () => {
    if (talksSegmentSelected.value === 'talk_candidates') {
      isReachedCandidateEnd.value = false;
      candidateTalkOffset.value = 0;
      isLoadingFirst.value = true;
      await getCandidatesData(candidateTalkOffset.value, talksSearchCriteria.value);
      isLoadingFirst.value = false;
      candidatesDataRef.value = candidatesData.value;
    }
    if (talksSegmentSelected.value === 'talk_projects' || talksSegmentSelected.value === 'talk_services') {
      isReachedProjectsEnd.value = false;
      projectTalkOffset.value = 0;
      isLoadingFirst.value = true;
      await getProjectsTalks(projectTalkOffset.value, talksSearchCriteria.value);
      isLoadingFirst.value = false;
      projectTalksDataRef.value = projectsTalksData.value;
    }
  });

  watch(talksSegmentSelected, async newTalksSegmentSelected => {
    await loadSegmentData(newTalksSegmentSelected);
  });

  const loadSegmentData = async (talksSegmentSelected: string) => {
    if (!isTalkRoute.value) {
      isLoadingFirst.value = false;
      isLoading.value = false;
      if (talksSegmentSelected === 'talk_all' && !talksData.value) {
        isLoadingFirst.value = true;
        await getTalks(hasRecruitmentServicesPermission.value);
        isLoadingFirst.value = false;
      }
      if (talksSegmentSelected === 'talk_candidates') {
        isReachedCandidateEnd.value = false;
        candidateTalkOffset.value = 0;
        isLoadingFirst.value = true;
        await getCandidatesData(candidateTalkOffset.value);
        candidatesDataRef.value = candidatesData.value;
        isLoadingFirst.value = false;
      }
      if (talksSegmentSelected === 'talk_projects' || talksSegmentSelected === 'talk_services') {
        if (!projectsTalksData.value) {
          isReachedProjectsEnd.value = false;
          projectTalkOffset.value = 0;
          isLoadingFirst.value = true;
          await getProjectsTalks(projectTalkOffset.value);
          projectTalksDataRef.value = projectsTalksData.value;
          isLoadingFirst.value = false;
        } else {
          projectTalksDataRef.value = projectsTalksData.value;
        }
      }
    }
  };

  const isMobile = computed(() => {
    return isPlatform('mobile');
  });

  const isTalkRoute = computed(() => {
    return route.name === 'ScoreTalk';
  });
</script>

<style scoped lang="scss">
  .sidebar {
  }

  @media (min-width: 900px) {
    .overlay {
      position: fixed;
      bottom: 0;
      right: 10px;

      display: flex;
      align-items: flex-end;
    }
  }

  @media (max-width: 899px) {
    .overlay {
      padding: 0;
      background-color: var(--background-primary);
    }

    .mobile {
      position: absolute;
      width: 100%;
      bottom: 0;
    }

    .emmyTalkOpen {
      position: relative;
      margin-top: 100%;
    }

    .emmyTalkFullscreen {
      margin-top: 0;
    }
  }
</style>
