const hostName = window.location.hostname;
// Will omit the solution name from the hostname. e.g. portal.<org-id>.emmysoft.com -> <org-id>.emmysoft.com
const organizationHostName = hostName.split('.').slice(-3).join('.');

export default {
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: process.env.VUE_APP_AWS_REGION,

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID,

    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: process.env.VUE_APP_COGNITO_CLIENT_ID,

    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    mandatorySignIn: true,

    // We will use this as temporarily setting, as long as we have users to migrate to new user pool we need this (this allows sending of pass to user migration lambda)
    authenticationFlowType: 'USER_PASSWORD_AUTH',
  },
};
