<template>
  <ion-card
    :class="showLeftBorder ? 'project-color' : ''"
    :style="showLeftBorder ? { borderLeftColor: candidateData.referenceColor } : ''"
    @click="openCandidate()"
  >
    <status-bar class="candidate-status" :display="candidateData.flowHistory.byNewestFirst" />
    <ion-card-header>
      <avatar :size="64" />
      <start-chat-button button="true" class="chat-button" @click.stop="openTalk()" />
      <div class="candidate__content">
        <ion-card-title class="wrap-text">
          {{ candidateData.contactInfo.fullName }}
        </ion-card-title>

        <ion-card-subtitle v-if="showProjectName" class="project wrap-text">
          {{ $t('emmyTalk.project') }}: {{ candidateData.jobOpeningName }}
        </ion-card-subtitle>
      </div>
    </ion-card-header>
  </ion-card>
</template>

<script setup lang="ts">
  import StartChatButton from '@/views/talk/components/messageOverviews/StartChatButton.vue';
  import StatusBar from '@/views/talk/components/messageOverviews/StatusBar.vue';
  import Avatar from '@/components/ui-elements/Avatar.vue';
  import { IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle } from '@ionic/vue';
  import { CandidateData } from '@/views/talk/model/CandidateData';
  import { useRouter } from 'vue-router';
  import {
    OpenTalkByReference,
    TalkType,
    useWebComponentsGlobalState,
  } from '@/composables/useWebComponentsGlobalState';

  const { openTalkId, overlayTalks, openTalkByReference } = useWebComponentsGlobalState();

  const router = useRouter();
  const props = defineProps<{
    candidateData: CandidateData;
    isOverlay: boolean;
    showLeftBorder: boolean;
    showProjectName: boolean;
  }>();

  const openCandidate = () => {
    router.push(`/project/${props.candidateData.jobOpeningId}/candidates/${props.candidateData.id}`);
  };

  const openTalk = () => {
    const talkReferenceObject: OpenTalkByReference = {
      applicationId: props.candidateData.id,
      talkType: TalkType.standard,
      title: props.candidateData.contactInfo.fullName,
      projectId: props.candidateData.jobOpeningId,
      emmyProjectId: props.candidateData.jobOpeningEmmyProjectId,
      referenceColor: props.candidateData.referenceColor,
    };

    openTalkId.value = undefined;
    if (props.isOverlay) {
      if (
        !overlayTalks.value.find(
          talk =>
            talk.applicationId === talkReferenceObject.applicationId && talk.talkType === talkReferenceObject.talkType,
        )
      ) {
        overlayTalks.value.push(talkReferenceObject);
      }
    } else {
      openTalkByReference.value = talkReferenceObject;
    }
  };
</script>

<style scoped lang="scss">
  ion-card {
    box-shadow: none;
    margin: 7px 4px;
    border-radius: 10px;
    width: 100%;
    padding: 10px 14px 25px;
    cursor: pointer;
  }

  ion-card-header {
    height: 45px;
    margin: 30px 0 3px;
    padding: 0;
    display: flex;
    align-items: flex-end;

    div {
      margin-left: 20px;
    }
  }

  ion-card-title {
    h4 {
      margin: 0;
      font-size: 1.3rem;
      font-weight: 600;
      color: var(--ion-color-primary);
    }

    p {
      font-size: 14px;
      margin-top: 4px;
      margin-bottom: 4px;
    }

    div {
      margin-left: 20px;
    }
  }

  ion-card-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: var(--font-title);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 180px;
  }

  ion-card-subtitle {
    margin: 0;
    align-self: flex-start;
    font-weight: 500;
    font-size: 11px;
    line-height: 13px;
  }

  ion-card-content {
    padding: 0;
    font-size: 1.2rem;

    p {
      font-size: 1.2rem;
      line-height: 1.5rem;
    }
  }

  .active {
    --background: var(--background-step-finished);

    ion-card-title h4,
    ion-card-content {
      color: var(--font-primary);
    }

    ion-card-subtitle {
      color: var(--primary);
    }
  }

  ion-button {
    margin-left: auto;
    --background: transparent;
    --border-color: #25a8a9;
    --border-radius: 50px;
    --border-width: 2px;
    --border-style: solid;
    --color: #25a8a9;

    text-transform: none;
    font-size: 1.3rem;
    font-weight: 600;

    ion-icon {
      margin-right: 10px;
    }
  }

  .candidate__content {
    max-width: 100%;
    height: 100%;
  }

  .project {
    font-weight: 600;
    font-size: 11px;
    line-height: 13px;
    color: var(--primary);
    margin-top: 5px;
    margin-bottom: 6px;
  }

  .wrap-text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 220px;
  }

  .inactiveOverlay {
    opacity: 0.75;
  }

  .chat-button {
    top: 10px;
    left: 15px;
  }

  .project-color {
    border-left-style: solid;
    border-left-width: 14px;
  }
</style>
