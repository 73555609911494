import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-673cb476"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["locale", "access-token", "id-token", "base-api-url", "talk-id", "current-user", "is-mobile", "title-color", "theme", "talk-type", "job-ids", "application-ids", "tenant-id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return ($props.openByReference)
    ? (_openBlock(), _createBlock($setup["IonCard"], {
        key: 0,
        style: _normalizeStyle({
      width: $setup.componentWidth + 'px',
      borderTopColor: $props.openByReference.referenceColor,
    }),
        class: "unsetWidth"
      }, {
        default: _withCtx(() => [
          ($setup.accessToken && $setup.idToken && $setup.currentUser)
            ? (_openBlock(), _createElementBlock("emmy-talk", {
                key: $setup.talkKey,
                locale: $setup.locale,
                "access-token": $setup.accessToken,
                "id-token": $setup.idToken,
                "base-api-url": $setup.talkApiUrl,
                "talk-id": $setup.talkId,
                "current-user": $setup.currentUser,
                "is-mobile": `${$setup.isMobile()}`,
                "is-overlay": true,
                "title-color": $setup.titleColor,
                theme: $setup.theme,
                "talk-type": $props.openByReference?.talkType,
                "job-ids": $setup.useEmmyTalk().getProjectReference($props.openByReference),
                "application-ids": $setup.useEmmyTalk().getApplicationsReference($props.openByReference),
                "tenant-id": $setup.tenantId,
                onEmmytalkClose: $setup.closeMessage,
                onEmmytalkToggleFullscreen: $setup.toggleFullscreen
              }, null, 40 /* PROPS, HYDRATE_EVENTS */, _hoisted_1))
            : _createCommentVNode("v-if", true)
        ]),
        _: 1 /* STABLE */
      }, 8 /* PROPS */, ["style"]))
    : _createCommentVNode("v-if", true)
}