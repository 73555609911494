<template>
  <div class="searchbar">
    <ion-searchbar
      v-model="talksSearchCriteria"
      :placeholder="$t('search.search')"
      inputmode="search"
      type="search"
      enterkeyhint="search"
      autocomplete="off"
      autocorrect="off"
    ></ion-searchbar>
  </div>
</template>

<script setup lang="ts">
  import { IonSearchbar } from '@ionic/vue';
  import { useWebComponentsGlobalState } from '@/composables/useWebComponentsGlobalState';
  import { watch } from 'vue';

  defineProps<{
    isOverlay: boolean;
  }>();
  const { talksSearchCriteria, talksSegmentSelected } = useWebComponentsGlobalState();

  watch(talksSegmentSelected, () => {
    talksSearchCriteria.value = undefined;
  });
</script>

<style scoped lang="scss">
  .searchbar {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-left: 10px;
    margin-right: 10px;
  }

  ion-searchbar {
    height: 42px;
    margin: 10px;
    padding: 0;
    --border-radius: 4px;
    --placeholder-color: var(--font-secondary);
    --icon-color: #9a9eb3;
  }

  ion-button {
    height: 42px;
    margin: 10px 10px 10px 0;
    // --padding-top: 25px;
    // --padding-bottom: 25px;
    // --padding-start: 15px;
    // --padding-end: 15px;
    --background: #fff;

    ion-icon {
      color: #9a9eb3;
    }
  }

  .active-label {
    color: rgb(38, 38, 38);
    text-transform: none;
  }
</style>
