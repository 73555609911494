<template>
  <ion-card class="project-color" :style="{ borderLeftColor: projectData.referenceColor }">
    <ion-card-header>
      <div class="display-flex">
        <ion-card-title class="message__title">
          {{ projectData.name }}
        </ion-card-title>
      </div>
      <ion-icon v-if="!isOpen" :icon="chevronDownOutline" @click="toggle()"></ion-icon>
      <ion-icon v-else :icon="chevronUpOutline" @click="toggle()"></ion-icon>
    </ion-card-header>
    <ion-card-content v-if="isOpen && sortedCandidates !== undefined">
      <project-candidate-message-overview
        v-for="candidate in sortedCandidates"
        :key="`project-candidate-message-overview_${candidate.id}`"
        class="candidates"
        :candidate="candidate"
        :is-overlay="isOverlay"
      />
    </ion-card-content>
    <ion-card-content v-if="!isOpen" class="project-avatar display-flex">
      <div class="project-buttons">
        <div class="icon-btn">
          <svg
            class="icon"
            width="26"
            height="26"
            viewBox="0 0 62 46"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            @click.stop="openProject()"
          >
            <path
              d="M55.7 8.3H43C42 3.9 37.6 0.5 32.4 0.5H29.4C24.0999 0.5 19.6999 3.9 18.7999 8.3H5.99995C2.79995 8.3 0.199951 10.9 0.199951 14.1V39.8C0.199951 43 2.79995 45.6 5.99995 45.6H55.7C58.9 45.6 61.5 43 61.5 39.8V14C61.5 10.9 58.9 8.3 55.7 8.3ZM29.4 4H32.4C35.7 4 38.5 5.8 39.4 8.3H22.4C23.2999 5.8 26.1 4 29.4 4ZM5.99995 11.8H55.7C56.9 11.8 58 12.8 58 14.1V22L32.9 26.6C32.5 26.7 31.9999 26.7 31.5999 26.6L3.79995 21.4V14C3.79995 12.8 4.79995 11.8 5.99995 11.8ZM55.7 42H5.99995C4.79995 42 3.69995 41 3.69995 39.7V25L30.9 30C31.2999 30.1 31.8 30.1 32.2 30.1C32.6 30.1 33.0999 30.1 33.5 30L58 25.5V39.7C58 41 57 42 55.7 42Z"
              fill="#FFFFFF"
            />
          </svg>
        </div>
        <div class="icon-btn">
          <svg
            class="icon"
            width="26"
            height="26"
            viewBox="0 0 23 17"
            xmlns="http://www.w3.org/2000/svg"
            fill="#FFFFFF"
            @click.stop="openTalk()"
          >
            <path
              d="M20.4124 0.115479H2.5874C1.40146 0.115479 0.431152 1.14449 0.431152 2.40216V14.5978C0.431152 15.4363 0.826465 16.1604 1.50928 16.5796C1.83271 16.7702 2.22803 16.8845 2.5874 16.8845C2.94678 16.8845 3.30615 16.7702 3.66553 16.5796L6.82803 14.6359C6.97178 14.5597 7.11553 14.5216 7.25928 14.5216H20.3765C21.5624 14.5216 22.5327 13.4926 22.5327 12.2349V2.40216C22.5687 1.14449 21.5983 0.115479 20.4124 0.115479ZM21.3108 12.2349C21.3108 12.7685 20.9155 13.1877 20.4124 13.1877H7.29521C6.93584 13.1877 6.54053 13.302 6.21709 13.4926L3.05459 15.4363C2.76709 15.5887 2.44365 15.5887 2.15615 15.4363C1.86865 15.2838 1.7249 14.9789 1.7249 14.6359V2.40216C1.7249 1.8686 2.12022 1.44938 2.62334 1.44938H20.4483C20.9515 1.44938 21.3468 1.8686 21.3468 2.40216V12.2349H21.3108Z"
            />
            <path
              d="M16.064 4.68884H6.43276C6.07339 4.68884 5.78589 4.99373 5.78589 5.37485C5.78589 5.75596 6.07339 6.06085 6.43276 6.06085H16.1C16.4593 6.06085 16.7468 5.75596 16.7468 5.37485C16.7468 4.99373 16.4234 4.68884 16.064 4.68884Z"
            />
            <path
              d="M14.5546 8.99545H6.43276C6.07339 8.99545 5.78589 9.30034 5.78589 9.68146C5.78589 10.0626 6.07339 10.3675 6.43276 10.3675H14.5906C14.95 10.3675 15.2375 10.0626 15.2375 9.68146C15.2375 9.30034 14.914 8.99545 14.5546 8.99545Z"
            />
          </svg>
        </div>
      </div>
    </ion-card-content>
  </ion-card>
</template>

<script setup lang="ts">
  import { IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonIcon } from '@ionic/vue';
  import { chevronDownOutline, chevronUpOutline } from 'ionicons/icons';

  import { ref, Ref, watch } from 'vue';
  import { useRouter } from 'vue-router';
  import { ProjectData } from '@/views/talk/model/ProjectData';
  import {
    OpenTalkByReference,
    TalkType,
    useWebComponentsGlobalState,
  } from '@/composables/useWebComponentsGlobalState';
  import { useProjectCandidatesList } from '@/views/candidate/composables/useJobCandidatesList';
  import ProjectCandidateMessageOverview from '@/views/talk/components/messageOverviews/ProjectCandidateMessageOverview.vue';
  import { JobCandidate } from '@/model/JobCandidate.model';

  const { openTalkId, openTalkByReference, overlayTalks } = useWebComponentsGlobalState();
  const { data: jobCandidatesList, loadProjectCandidatesList } = useProjectCandidatesList();

  const isOpen: Ref<boolean> = ref(false);
  const sortedCandidates: Ref<JobCandidate[] | undefined> = ref([]);
  const router = useRouter();

  const props = defineProps<{
    projectData: ProjectData;
    isOverlay: boolean;
    isRecruitmentServices?: boolean;
  }>();

  const toggle = () => {
    const newIsOpen = !isOpen.value;
    isOpen.value = newIsOpen;

    if (newIsOpen) {
      loadProjectCandidatesList(props.projectData.id);
    }
  };

  const openProject = () => {
    router.push(`/project/${props.projectData.id}`);
  };

  const openTalk = () => {
    const talkReferenceObject: OpenTalkByReference = {
      talkType: props.isRecruitmentServices ? TalkType.recruiter_service : TalkType.standard,
      title: props.projectData.name,
      projectId: props.projectData.id,
      emmyProjectId: props.projectData.emmyProjectId,
      referenceColor: props.projectData.referenceColor,
    };

    openTalkId.value = undefined;
    if (props.isOverlay) {
      if (
        !overlayTalks.value.find(talk => {
          return (
            (talk.emmyProjectId === talkReferenceObject.emmyProjectId ||
              talk.projectId === talkReferenceObject.projectId) &&
            talk.talkType === talkReferenceObject.talkType
          );
        })
      ) {
        overlayTalks.value.push(talkReferenceObject);
      }
    } else {
      openTalkByReference.value = talkReferenceObject;
    }
  };

  watch(jobCandidatesList, candidatesList => {
    if (jobCandidatesList.value) {
      sortedCandidates.value = jobCandidatesList.value.sort(function (x, y) {
        const xLatestChange = x.flowHistory?.byNewestFirst[0]?.inStateSince;
        const yLatestChange = y.flowHistory?.byNewestFirst[0]?.inStateSince;

        if (xLatestChange && !yLatestChange) {
          return -1;
        }

        if (!xLatestChange && yLatestChange) {
          return 1;
        }

        if (xLatestChange && yLatestChange) {
          const xLatestChangeDate = new Date(xLatestChange);
          const yLatestChangeDate = new Date(yLatestChange);

          if (xLatestChangeDate.getTime() > yLatestChangeDate.getTime()) {
            return -1;
          }

          if (xLatestChangeDate.getTime() < yLatestChangeDate.getTime()) {
            return 1;
          }
        }

        return 0;
      });
    } else {
      sortedCandidates.value = candidatesList;
    }
  });
</script>

<style scoped lang="scss">
  ion-card {
    box-shadow: none;
    margin: 7px 4px;
    padding: 7px 16px;
    border-radius: 10px;
    width: 100%;
  }

  ion-card-header {
    margin: 0 0 3px;
    padding: 0;
    display: flex;
    align-items: center;

    .abbreviation-icon {
      height: 30px;
      width: 30px;
      margin-right: 10px;
      background: #33c6d1;
      padding: 15px;
      border-radius: 100px;
      color: var(--font-white);
    }

    ion-icon {
      margin-left: auto;
    }
  }

  .display-flex {
    display: flex;
    align-items: center;
  }

  ion-card-title {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin: 0;
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
    color: #33c6d1;
    padding-right: 10px;
    margin-left: 10px;

    .message__title {
      margin-left: 14px;
    }
  }

  ion-card-subtitle {
    margin: 0;
    align-self: flex-start;
    font-size: 1rem;
    color: #889fb2;
  }

  ion-card-content {
    margin-top: 12px;
    margin-bottom: 12px;
    padding: 0;
    font-size: 1.2rem;

    p {
      font-size: 1.2rem;
      line-height: 1.5rem;
    }

    ion-card {
      padding-left: 10px;
      padding-right: 10px;
      margin-left: 0px;
      margin-right: 0px;
    }

    ion-icon {
      height: 34px;
      width: 34px;
    }
  }

  .active {
    --background: var(--project-message-button-active-color);

    ion-card-title h4,
    ion-card-content {
      color: var(--font-primary);
    }

    ion-card-subtitle {
      color: var(--primary);
    }
  }

  ion-button {
    margin-left: auto;
    --background: transparent;
    --border-color: #25a8a9;
    --border-radius: 50px;
    --border-width: 2px;
    --border-style: solid;
    --color: #25a8a9;

    text-transform: none;
    font-size: 1.3rem;
    font-weight: 600;

    ion-icon {
      margin-right: 10px;
    }
  }

  .display-flex {
    display: flex;
    width: 95%;
  }

  .candidates-avatar {
    margin-left: -4px;
    box-shadow: 0px 1px 20px rgb(0 0 0 / 10%);
  }

  .candidates {
    box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    margin: 20px 0;
  }

  .footer {
    height: 32px;
    display: flex;
    justify-content: space-between;
    text-align: center;

    .open-profile {
      height: 32px;
      --border: 1px solid #50c6d2;
      --border-radius: 27px;
      background: var(--background-step-finished);
      border-radius: 27px;
      color: var(--font-white);
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      width: 200px;
      min-height: 32px !important;
      margin-right: 2px;
      --border-color: var(--font-title);
    }

    .open-chat {
      height: 32px;
      --border: 1px solid #50c6d2;
      --box-sizing: border-box;
      --border-radius: 27px;
      color: var(--font-title);
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      width: 200px;
      margin-left: 2px;
      --border-color: var(--font-title);
    }
  }

  .project-avatar {
    margin-top: 8px;
  }

  .project-buttons {
    display: flex;
    flex-direction: row;
    gap: 4px;
    margin-left: auto;
  }

  ion-card-content {
    ion-icon {
      float: left;
      height: 18px;
      width: 18px;
      margin-left: 6px;
      background: var(--circle-background-emmytalk);
      padding: 8px;
      border-radius: 100px;
      color: var(--circle-color-emmytalk);
      cursor: pointer;
      box-shadow: 0px 1px 20px rgb(0 0 0 / 10%);
    }
  }

  .inactiveOverlay {
    opacity: 0.75;
  }

  .project-color {
    border-left-style: solid;
    border-left-width: 14px;
  }

  .icon-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    float: left;
    height: 36px;
    width: 36px;
    background: var(--circle-background-emmytalk);
    border-radius: 100px;
    color: var(--circle-color-emmytalk);
    cursor: pointer;
    box-shadow: 0px 1px 20px rgb(0 0 0 / 10%);

    & > * {
      margin: 8px;
    }
  }
</style>
